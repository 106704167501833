import { createAsyncThunk } from '@reduxjs/toolkit'
import { setWelcomeEmailMutation } from 'gql/publication/mutations'
import { AppState } from 'redux/store'
import { selectPublication } from 'redux/publication/selector'
import { fetchPublication } from 'redux/publication/slice'

export const setWelcomeEmails = createAsyncThunk(
  'auth/setWelcomeEmails',
  async (
    data: {
      freeWelcomeEmail: string
      paidWelcomeEmail?: string
      isNotUniversal: boolean
    },
    { getState, dispatch },
  ) => {
    const state = getState() as AppState
    const { id } = selectPublication(state)

    await setWelcomeEmailMutation(id, {
      subscriptionType: 'FREE',
      message: data.freeWelcomeEmail,
      isUniversal: !data.isNotUniversal,
    })

    if (data.isNotUniversal) {
      await setWelcomeEmailMutation(id, {
        subscriptionType: 'PAID',
        message: data.paidWelcomeEmail,
        isUniversal: !data.isNotUniversal,
      })
    }

    return await dispatch(fetchPublication()).unwrap()
  },
)

import { AuthTypeEnum } from '@tellimer/ui-analytics'
import { deleteKeyFromLocationSearch, getCurrentSerachParams } from 'helper/location'
import { Creator } from 'types/creator.types'
import { trackSignup, trackLogin, trackVerified, updateLastLogin } from './scriberAnalyticsApi'

const recordSignup = async (creator: Creator, type: AuthTypeEnum) => {
  if (!type) return

  await trackSignup(type, {
    id: creator.userId,
    name: creator.fullName,
    email: creator.email,
  })

  deleteKeyFromLocationSearch('signup')
}

const recordLogin = async (creator: Creator, type: AuthTypeEnum) => {
  if (!type) return

  await trackLogin(type, {
    id: creator.userId,
    name: creator.fullName,
    email: creator.email,
  })

  deleteKeyFromLocationSearch('login')
}

/**
 * This method will record any signup, login or verified actions that take place during the
 * initialisation of the app. To do so it looks at the url for any `signup`, 'login' or `verified`
 * querystring and fires the proper tracking. If none of those actions are found it does fire a call
 * to just update the last login date
 * @param creator
 */
export const recordUserAuth = async (creator: Creator) => {
  const search = getCurrentSerachParams()

  const signupType = search?.get('signup') || ''
  const loginType = search?.get('login') || ''
  const isVerified = search?.has('verified')

  if (signupType) {
    await recordSignup(creator, signupType as AuthTypeEnum)
  } else if (loginType) {
    await recordLogin(creator, loginType as AuthTypeEnum)
  } else if (isVerified) {
    await trackVerified()
    deleteKeyFromLocationSearch('verified')
  } else {
    await updateLastLogin({
      name: creator.fullName,
      email: creator.email,
    })
  }
}

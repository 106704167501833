import { gql } from '@tellimer/graphql-client'

export const emailListFileUpdated = gql`
  subscription emailListFileUpdated($publicationId: ID!) {
    emailListFileUpdated(publicationId: $publicationId) {
      publicationId
      creatorId
      errorMessage
      name
      status
      totalContactsToImport
      totalImportedContacts
      totalSkippedContacts
    }
  }
`

import { createAction } from '@reduxjs/toolkit'
import { POST_SORT_OPTIONS } from 'helper/constants'
import { PostSort } from 'types/post.types'

export const setSort = createAction('post/setSort', (value?: string) => {
  const querySort = POST_SORT_OPTIONS.find(item => item.slug === value)?.value || PostSort.NEWEST

  return {
    payload: {
      sort: value,
      querySort,
    },
  }
})

import { createSlice } from '@reduxjs/toolkit'
import {
  Subscription,
  Subscriber,
  SubscriberSort,
  SubscriberFilterParams,
} from 'types/subscriber.types'
import { NotificationManager, NotificationLayout } from '@tellimer/ui/Notification'

import { fetchSubscribers } from './fetchSubscribers'
import { fetchPageSubscribers } from './fetchPageSubscribers'
import { getTotalSubscribers } from './getTotalSubscribers'
import { fetchPostAccessTypes } from 'redux/postDetail/actions'
import { PostAccess } from 'gql/post/types'
import { exportSubscribers } from './actions/exportSubscribers'
import { initExportSubscribersSubscription } from './actions/initExportSubscribersSubscription'

export interface SubscriberState {
  init: boolean
  subscriptions: Subscription[]
  subscribers: Subscriber[]
  totalCount: number
  cursor: string
  fetching: boolean
  loadingMore: boolean
  searching: boolean
  currentPage: number
  sort: SubscriberSort
  filterParams?: SubscriberFilterParams
  totalPerAccess: Record<string, number>
  exportSubscribers: {
    exporting: boolean
    exportingSubscriptionInitiated: boolean
  }
}

export const initialState: SubscriberState = {
  init: false, // False until the first fetch
  subscriptions: [],
  subscribers: [],
  totalCount: 0,
  cursor: '',
  fetching: false,
  loadingMore: false,
  searching: false,
  currentPage: 0,
  sort: SubscriberSort.NEWEST,
  filterParams: {},
  totalPerAccess: {},
  exportSubscribers: {
    exporting: false,
    exportingSubscriptionInitiated: false,
  },
}

export const subscriberSlice = createSlice({
  name: 'subscribers',
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload
    },
    setSearching(state, action) {
      state.searching = action.payload
    },
    setExporting(state, action) {
      state.exportSubscribers.exporting = action.payload
    },
    setExportingSubscriptionInitiated(state) {
      state.exportSubscribers.exportingSubscriptionInitiated = true
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSubscribers.pending, state => {
        state.fetching = !state.loadingMore && !state.searching
      })
      .addCase(fetchSubscribers.rejected, (state, action) => {
        state.fetching = false
        state.searching = false
        console.error(action.error)
        NotificationManager.create({
          type: 'error',
          layout: NotificationLayout.CONDENSED,
          title: 'An error occurred while loading your subscribers',
        })
      })
      .addCase(fetchSubscribers.fulfilled, (state, action) => {
        const { data, meta, sort, filterParams, loadMore } = action.payload
        state.init = true
        state.totalCount = meta?.totalCount
        state.cursor = meta?.cursor
        state.sort = sort
        state.filterParams = filterParams || {}
        if (loadMore) {
          state.subscribers = [...state.subscribers, ...(data || [])]
        } else {
          state.currentPage = 0
          if (data) {
            state.subscribers = [...(data || [])]
          }
        }
        state.fetching = false
        state.searching = false
      })
      .addCase(fetchPageSubscribers.pending, state => {
        state.loadingMore = true
      })
      .addCase(fetchPageSubscribers.rejected, state => {
        state.loadingMore = false
      })
      .addCase(fetchPageSubscribers.fulfilled, (state, action) => {
        state.currentPage = action.payload
        state.loadingMore = false
      })

      .addCase(getTotalSubscribers.fulfilled, (state, action) => {
        state.totalCount = action.payload
      })

      .addCase(fetchPostAccessTypes.fulfilled, (state, action) => {
        state.totalPerAccess = action.payload.reduce(
          (result: Record<string, number>, postAccess: PostAccess) => {
            result[postAccess.id] = postAccess.emailTotal || 0
            return result
          },
          {},
        )
      })
      .addCase(exportSubscribers.pending, state => {
        state.exportSubscribers.exporting = true
      })
      .addCase(exportSubscribers.rejected, state => {
        state.exportSubscribers.exporting = false
      })
      .addCase(initExportSubscribersSubscription.fulfilled, state => {
        state.exportSubscribers.exportingSubscriptionInitiated = true
      })
      .addCase(initExportSubscribersSubscription.rejected, state => {
        state.exportSubscribers.exportingSubscriptionInitiated = false
      })
  },
})

export { fetchPageSubscribers } from './fetchPageSubscribers'
export { fetchSubscribers } from './fetchSubscribers'
export { exportSubscribers } from './actions/exportSubscribers'

export const { setCurrentPage } = subscriberSlice.actions
export const { setSearching } = subscriberSlice.actions
export const { setExporting } = subscriberSlice.actions

export default subscriberSlice.reducer

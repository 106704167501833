import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

// redux
import { selectPublication, selectPublicationDomain } from 'redux/publication/selector'
import { selectTotalCountOfSubscribers } from 'redux/subscriber/selector'

// hooks
import { useAppSelector } from 'hooks/reduxHooks'

import * as Glyph from '@tellimer/ui/Icon'
import { Popover } from '@tellimer/ui/headless'

import SearchInput from './SearchInput'
import NewPostButton from './NewPostButton'

type navItemsType = {
  path: string
  icon: any
  title: string
  badge?: number
}[]

type NavItemsProps = {
  closePopover: () => void
}

const NavItems: React.FC<NavItemsProps> = ({ closePopover }) => {
  const router = useRouter()
  const publication = useAppSelector(selectPublication)
  const publicationDomain = useSelector(selectPublicationDomain)
  const totalCountOfSubscribers = useAppSelector(selectTotalCountOfSubscribers)

  const navItems: navItemsType = [
    { path: '/', icon: Glyph.Dashboard, title: 'Dashboard' },
    { path: '/posts', icon: Glyph.Posts, title: 'Posts' },
    {
      path: '/subscribers',
      icon: Glyph.Subscribers,
      title: 'Subscribers',
      badge: totalCountOfSubscribers,
    },
    { path: '/settings/account', icon: Glyph.Settings, title: 'Settings' },
  ]

  const classes = {
    navItem: (active = false) =>
      classNames(
        'flex items-center justify-between p-3 lg:p-2 rounded-md cursor-pointer group hover:bg-gray-800',
        { 'bg-gray-800': active },
      ),
    navIcon: (active = false) =>
      classNames('w-6 h-6 mr-3 fill-gray-400', {
        'text-white': active,
      }),
    navTitle: (active = false) =>
      classNames('text-gray-300 text-base lg:text-sm font-medium', {
        'text-white': active,
      }),
  }

  return (
    <>
      <div className="px-5 lg:px-4 py-7 lg:py-6">
        {publication?.name && publicationDomain && (
          <div className="flex items-center justify-between">
            <a
              className="w-auto lg:w-full"
              href={publicationDomain}
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex items-center lg:justify-between">
                <span className="mr-2.5 lg:mr-0 text-white font-medium font-brick text-xl lg:text-lg leading-6">
                  {publication.name}
                </span>
                <Glyph.Eye className="w-5 text-white" title="View publication" />
              </div>
            </a>
            <Popover.Button>
              <Glyph.Close className="lg:hidden w-5 text-white" title="Close menu" />
            </Popover.Button>
          </div>
        )}
        <NewPostButton className="mt-6 hidden lg:flex" withText />
      </div>

      <div className="sidebar-items-divider" />
      <div className="px-2 lg:px-4 pt-3 lg:py-6 flex flex-col gap-1">
        {navItems.map(({ path, icon: Icon, title, badge }, idx) => {
          const isActive = router?.asPath === path
          return (
            <Link key={idx} href={path} passHref>
              <a className={classes.navItem(isActive)} onClick={closePopover}>
                <div className="flex items-center">
                  <Icon className={classes.navIcon(isActive)} title={title} />
                  <span className={classes.navTitle(isActive)}>{title}</span>
                </div>
                {typeof badge !== 'undefined' && (
                  <span
                    className={`px-3 py-0.5 flex items-center justify-center group-hover:bg-gray-600 rounded-xl text-xs font-medium text-gray-300 ${
                      isActive ? 'bg-gray-600' : 'bg-gray-800'
                    }`}
                  >
                    {badge}
                  </span>
                )}
              </a>
            </Link>
          )
        })}
      </div>

      <div className="hidden lg:block sidebar-items-divider" />
      <div className="px-2 lg:px-4 pt-1 pb-3 lg:py-6 flex flex-col lg:gap-1">
        <div className="hidden lg:block">
          <SearchInput />
        </div>
        <a
          className={classes.navItem()}
          href="https://tellimer.notion.site/Scriber-Resources-901d064df24942a5a99aafcae5246525"
          target="_blank"
          rel="noreferrer"
        >
          <div className="flex items-center">
            <Glyph.Help className={classes.navIcon()} title="Help" />
            <span className={classes.navTitle()}>Help</span>
          </div>
        </a>
      </div>

      <div className="block lg:hidden sidebar-items-divider" />
      <div className="mx-5 lg:hidden">
        <NewPostButton className="w-full my-6 flex items-center justify-center" withText />
      </div>
    </>
  )
}

export default NavItems

import { gql } from '@tellimer/graphql-client'
export * from '../common'

export const SUBSCRIBER_COMMON_FIELDS = gql`
  fragment SubscriberCommonFields on Subscriber {
    createdOn: createdAt
    fullName
    email
    profilePicture
    hasVerifiedEmail
  }
`

export const SUBSCRIPTION_COMMON_FIELDS = gql`
  fragment SubscriptionCommonFields on ScriberSubscription {
    createdOn: createdAt
    subscriptionType
    analytics {
      emailsReceived
      emailsOpened
      webViews
    }
    actions {
      canDelete
      canDowngrade
      canExport
    }
    status
    billing {
      billingInterval
      billingAmount
      discountPercentage
      signedUpAt
      nextInvoiceAt
      totalSpent
      viewSubscriberUrl
    }
    hasOptedOut
  }
`

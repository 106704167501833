import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { handleMutationResponse } from 'gql/util'
import { Publication } from 'types/publication.types'
import { ERROR_FIELDS, PUBLICATION_COMMON_FIELDS } from '../fragments'

export const MUTATION_UPDATE_PUBLICATION = gql`
  mutation UpdatePublication($data: UpdatePublicationInput!) {
    publication: updatePublication(data: $data) {
      data {
        ...PublicationCommonFields
      }
      ${ERROR_FIELDS}
    }
  }
  ${PUBLICATION_COMMON_FIELDS}
`

export const updatePublicationMutation = async (id: string, data: Partial<Publication>) => {
  if (!id) {
    throw new Error("Can't update a publication without id")
  }
  if (!data) {
    throw new Error("Can't updated a publication without data")
  }

  const res = await mutation(MUTATION_UPDATE_PUBLICATION, { data: { id, ...data } })
  return handleMutationResponse(res, 'publication') as Publication
}

import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { handleMutationResponse } from 'gql/util'
import { ERROR_FIELDS } from '../fragments'

export const MUTATION_SEND_TEST_WELCOME_EMAIL = gql`
  mutation SendTestWelcomeEmail($email: String, $welcomeMessage: String) {
    sendTestWelcomeEmail(email: $email, welcomeMessage: $welcomeMessage) {
      data
      ${ERROR_FIELDS}
    }
  }
`

export const sendTestWelcomeEmailMutation = async (email: string, welcomeMessage: string) => {
  if (!(email && welcomeMessage)) {
    throw new Error("Can't send a welcome email test without an email and a welcome message")
  }

  const res = await mutation(MUTATION_SEND_TEST_WELCOME_EMAIL, { email, welcomeMessage })
  handleMutationResponse(res, 'sendTestWelcomeEmail')
}

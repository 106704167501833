import { createAsyncThunk } from '@reduxjs/toolkit'
import { selectPublication } from '../../publication/selector'
import { unsubscribeByEmailMutation } from 'gql/subscriber'
import { Subscriber } from 'types/subscriber.types'

export const deleteSubscriber = createAsyncThunk(
  'subscriber/deleteSubscriber',
  async (subscriber: Subscriber, { getState }) => {
    const state = getState() as any
    const publication = selectPublication(state)

    if (!subscriber.email || !publication) {
      throw new Error("Can't delete a subscriber without a publication adn a subscriber email")
    }

    await unsubscribeByEmailMutation({ email: subscriber.email, publicationId: publication.id })
  },
)

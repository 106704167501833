import { createAsyncThunk } from '@reduxjs/toolkit'
import { updatePublicationMutation } from 'gql/publication/mutations'
import { trackPublicationUpdate } from 'helper/analytics'
import omitDeep from 'helper/omitDeep'
import { AppState } from 'redux/store'
import { selectPublication } from '../selector'

export const updatePublicationDetails = createAsyncThunk(
  'auth/updatePublicationDetails',
  async (
    { name, about, domainName }: { name: string; about: string; domainName: string },
    { getState },
  ) => {
    const state = getState() as AppState
    const { id } = selectPublication(state) || {}

    const publication = await updatePublicationMutation(
      id,
      omitDeep({
        name,
        about,
        domain: {
          name: domainName,
          isSubdomain: true,
        },
      }),
    )

    await trackPublicationUpdate()

    return publication
  },
)

import { createAsyncThunk } from '@reduxjs/toolkit'
import { getPostQuery } from 'gql/post/queries'

export const fetchPost = createAsyncThunk('postDetail/fetchPost', async (postId: string) => {
  const post = await getPostQuery(postId)

  return {
    ...post,
    body: post.body ? JSON.parse(post.body) : post.body,
  }
})

import { createAsyncThunk } from '@reduxjs/toolkit'
import { selectPublication } from '../../publication/selector'
import { getSubscriberQuery, updateSubscriberMutation } from 'gql/subscriber'

export const updateSubscriber = createAsyncThunk(
  'subscriber/updateSubscriber',
  async ({ id, fullName }: { id: string; fullName: string }, { getState }) => {
    const state = getState() as any
    const publication = selectPublication(state)

    if (!id || !publication || !fullName) {
      throw new Error(
        "Can't delete a subscriber without a publication, a subscriber id and a full name",
      )
    }

    await updateSubscriberMutation({
      subscriberId: id,
      publicationId: publication.id || '',
      fullName: fullName || '',
    })

    // The update doesn't return so we refetch
    return await getSubscriberQuery(id, publication.id)
  },
)

import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppState } from 'redux/store'
import { OnboardingProgress } from 'types/onboarding.types'
import { selectOnboardingProgress } from './selector'
import { updateUserOnboardingProgress } from './updateOnboardingProgress'

export const dismissAccountSetup = createAsyncThunk(
  'onboardingProgress/dismissAccountSetup',
  async (_, { getState, dispatch }) => {
    const state = getState() as AppState
    const progress = selectOnboardingProgress(state) || {}

    const payload = {
      ...progress,
      isDismissed: true,
    } as OnboardingProgress

    await dispatch(updateUserOnboardingProgress(payload)).unwrap()
  },
)

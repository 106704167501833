import { gql } from '@tellimer/graphql-client'
import { query } from 'gql'
import { handleQueryResponse } from 'gql/util'

import { ERROR_FIELDS } from '../fragments'
import { PostAccess } from '../types'

export const QUERY_LIST_POST_ACCESS_TYPES = gql`

  query ListPostAccessTypes($publicationId: ID!) {
    listPostAccessTypes(publicationId: $publicationId) {
      data {
        name
        id
        hasPaywall
        isDefault
        emailTotal
      }
      ${ERROR_FIELDS}
    }
  }
`

export const listPostAccessTypesQuery = async (publicationId: string) => {
  const res = await query(QUERY_LIST_POST_ACCESS_TYPES, { publicationId })

  return handleQueryResponse(res, 'listPostAccessTypes') as Array<PostAccess>
}

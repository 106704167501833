import { createSlice } from '@reduxjs/toolkit'
import { SubscriptionDetails } from 'types/payment.types'

import { setupStripeConnect, initPaymentsSettings, saveSubscriptionPackages } from './actions'

export interface PaymentState {
  error?: string
  isLoading: boolean
  types?: SubscriptionDetails[]
  settings?: {
    isConnected: boolean
    accountName?: string
    accountId?: string
    stripeUrl?: string
  }
  isSettingUpStripe?: boolean
  isSaving?: boolean
  hasPaidSubscribers: boolean
}

export const initialState: PaymentState = {
  isLoading: false,
  types: [],
  hasPaidSubscribers: false,
}

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // Fetch payments settings
    builder
      .addCase(initPaymentsSettings.pending, state => {
        state.isLoading = true
      })
      .addCase(initPaymentsSettings.fulfilled, (state, action) => {
        const { types, settings, hasPaidSubscribers } = action.payload
        state.types = types
        state.settings = settings
        state.isLoading = false
        state.hasPaidSubscribers = hasPaidSubscribers
      })
      .addCase(initPaymentsSettings.rejected, state => {
        state.isLoading = false
      })

      // Setup stripper connect
      .addCase(setupStripeConnect.pending, state => {
        state.isSettingUpStripe = true
      })
      .addCase(setupStripeConnect.fulfilled, state => {
        state.isSettingUpStripe = false
      })
      .addCase(setupStripeConnect.rejected, state => {
        state.isSettingUpStripe = false
      })

      // Save subscription tiers
      .addCase(saveSubscriptionPackages.pending, state => {
        state.isSaving = true
      })
      .addCase(saveSubscriptionPackages.fulfilled, (state, action) => {
        state.types = action.payload.subscriptionTypes
        state.isSaving = false
      })
      .addCase(saveSubscriptionPackages.rejected, state => {
        state.isSaving = false
      })
  },
})

export default paymentsSlice.reducer

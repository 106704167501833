import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { handleQueryResponse } from '../../util'
import { ERROR_FIELDS } from '../fragments'

export const MUTATION_GENERATE_POST_SLUG = gql`
  mutation GeneratePostSlug($slugInput: GeneratePostSlugInput) {
    generatePostSlug(slugInput: $slugInput) {
      data
      ${ERROR_FIELDS}
    }
  }
`

export const generatePostSlugMutation = async (publicationId: string, slug: string) => {
  if (!publicationId) {
    throw new Error('Missing publication while generating the post slug')
  }

  if (!slug) {
    throw new Error('Missing slug while generating the post slug')
  }

  const res = await mutation(MUTATION_GENERATE_POST_SLUG, {
    slugInput: {
      publicationId,
      value: slug,
    },
  })
  const result = handleQueryResponse(res, 'generatePostSlug') as string
  return result
}

import { gql } from '@tellimer/graphql-client'
import { query } from 'gql'
import { handleQueryResponse } from 'gql/util'
import { Subscriber } from 'types/subscriber.types'
import { ERROR_FIELDS, SUBSCRIBER_COMMON_FIELDS, SUBSCRIPTION_COMMON_FIELDS } from './fragments'

export const QUERY_GET_SUBSCRIBER = gql`
  ${SUBSCRIBER_COMMON_FIELDS}
  ${SUBSCRIPTION_COMMON_FIELDS}
  query GetSubscriber($id: ID!, $publicationId: ID!) {
    getSubscriber(id: $id, publicationId: $publicationId) {
      data {
        id
        ...SubscriberCommonFields
        currentSubscription {
          ...SubscriptionCommonFields
        }
      }
      ${ERROR_FIELDS}
    }
  }
`

export const getSubscriberQuery = async (id: string, publicationId: string) => {
  const response = await query(QUERY_GET_SUBSCRIBER, { id, publicationId })
  return handleQueryResponse(response, 'getSubscriber') as Subscriber
}

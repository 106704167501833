import { gql } from '@tellimer/graphql-client'
export * from '../common'

export const PUBLICATION_COMMON_FIELDS = gql`
  fragment PublicationCommonFields on Publication {
    id
    name
    hasPaidPackage
    domain {
      name
      isSubdomain
      fullDomain
    }
    about
    seo {
      metaTitle
      metaDescription
    }
    socialAccounts {
      facebook
      twitter
      linkedIn
    }
    emailSettings {
      isWelcomeEmailUniversal
      welcomeEmails {
        message
        subscriptionType
      }
    }
    customisation {
      accentColor
      logo
      favicon
    }
    navigationLink {
      label
      url
    }
    hasPaidPackage
  }
`

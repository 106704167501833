import { datadogRum } from '@datadog/browser-rum'
import packageDetails from 'package.json'

const currentEnv: string = process.env.RUM_ENV || 'development'

const tracingOrigins = {
  production: ['https://scriber.to', /https:\/\/.*\.scriber\.to/],
  staging: ['https://scriber.tellimer.io', /https:\/\/.*\.scriber\.tellimer\.io/],
}

export function initDatadogRUM() {
  if (currentEnv === 'production' || currentEnv === 'staging') {
    datadogRum.init({
      applicationId: '6cecb72e-b623-4a6b-9eb4-7f28397e6a5f',
      clientToken: 'pubd806a4917d56b592c7f2a301ab8564f2',
      site: 'datadoghq.com',
      service: 'scriber-creator-tool',
      env: currentEnv,
      sampleRate: 100,
      trackInteractions: true,
      version: process.env.VERSION || packageDetails.version || 'unknown',
      allowedTracingOrigins: tracingOrigins[currentEnv],
    })
  }
}

import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { handleQueryResponse } from 'gql/util'
import { ERROR_FIELDS } from '../fragments'

export const MUTATION_CHANGE_PASSWORD = gql`
  mutation changePassword($data: ChangePasswordInput) {
    changePassword(data: $data) {
      data
      ${ERROR_FIELDS}
    }
  }
`

type ChangePasswordFields = {
  password: string
  oldPassword?: string
}

export const changePasswordMutation = async (data: Partial<ChangePasswordFields>) => {
  if (!data) {
    throw new Error("Can't change password without variables")
  }
  if (!data.password) {
    throw new Error("Can't change password without a valid new password")
  }

  const res = await mutation(MUTATION_CHANGE_PASSWORD, {
    data,
  })

  return handleQueryResponse(res, 'changePassword') as boolean
}

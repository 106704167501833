import { gql } from '@tellimer/graphql-client'
export * from '../common'

export const POST_COMMON_FIELDS = gql`
  fragment PostCommonFields on Post {
    title
    subtitle
    slug
    body
    featuredImage
    featuredImageAlt
    featuredImageCaption
    featuredImagePresence
    emailSubject
    readTime
    publishTo
    emailRecipientCount
    isNew
    createdOn
    publishedAt
    savedOn
    isPublished
    metrics {
      opens
      openPercentage
      clickPercentage
      unsubscribePercentage
      webViews
    }
    summary
    metaDescription
    useMetaDescription
    postAccess {
      name
      id
      hasPaywall
      isDefault
    }
  }
`

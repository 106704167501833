import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { NotificationLayout, NotificationManager } from '@tellimer/ui'

import { emailListFileUpdated } from 'gql/dashboard'
import { subscription } from 'gql'

import { fetchSubscribers } from 'redux/subscriber/fetchSubscribers'
import { selectCreator } from 'redux/auth/selector'

import { updateImportStatus, ImportStatus } from './updateImportStatus'
import { setIsImportInProgress } from './uploadSubscribers'

export type ImportInfoType = {
  errorMessage?: string
  name: string
  status: ImportStatus
  totalContactsToImport: number
  totalImportedContacts: number
  totalSkippedContacts: number
}

export const setImportSubscribersDetails = createAction<any>(
  'importSubscribers/setImportSubscribersDetails',
)

export const initImportSubscription = createAsyncThunk(
  'importSubscribers/initImportSubscription',
  async (_, { getState, dispatch }) => {
    const state = getState() as any
    const creator = selectCreator(state)
    const publicationId = creator.publication?.id

    subscription(emailListFileUpdated, { publicationId }, async (res: any) => {
      try {
        if (res?.error) {
          throw new Error(res?.error)
        }

        const importInfo = res?.data?.emailListFileUpdated as ImportInfoType
        if (!importInfo) {
          throw new Error('Import info missing from subscription')
        }

        console.log(`>>> Import update to "${importInfo.status}":`, importInfo)

        if (importInfo.errorMessage) {
          throw new Error(importInfo.errorMessage)
        }

        if (importInfo.status === ImportStatus.CANCELLED) {
          dispatch(updateImportStatus(ImportStatus.NOT_STARTED))
          dispatch(setIsImportInProgress(false))
        } else {
          dispatch(updateImportStatus(importInfo.status))
          dispatch(setImportSubscribersDetails(importInfo))
        }

        if (importInfo.status === ImportStatus.IMPORTED) {
          dispatch(fetchSubscribers({}))
        }
      } catch (err) {
        console.error(err)
        NotificationManager.create({
          type: 'error',
          layout: NotificationLayout.CONDENSED,
          title: 'An error occurred while importing your contacts',
        })

        dispatch(updateImportStatus(ImportStatus.ERROR_PROCESSING))
      }
    })
  },
)

import { gql } from '@tellimer/graphql-client'

export const updateOnboardingProgressSubscription = gql`
  subscription UpdateOnboardingProgressSubscription($publicationId: ID!) {
    onboardingProgressUpdated(publicationId: $publicationId) {
      publicationId
      completedPercentage
      isDismissed
      publicationStepCompleted
      publicationStepSkipped
      brandingStepCompleted
      brandingStepSkipped
      contactsStepCompleted
      contactsStepSkipped
      verifyEmailStepCompleted
      verifyEmailStepSkipped
      publishFirstPostStepCompleted
      gettingPaidStepCompleted
      gettingPaidStepSkipped
    }
  }
`

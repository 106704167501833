import { AppState } from 'redux/store'

export const selectSubscriberDetails = (state: AppState) => state.subscriberDetail.subscriber
export const selectSubscriberDetailsIsLoading = (state: AppState) =>
  state.subscriberDetail.isLoading

export const selectSubscriberSubscription = (state: AppState) =>
  state.subscriberDetail.subscriber?.currentSubscription

export const selectSubscriberHasOptedOut = (state: AppState) =>
  selectSubscriberSubscription(state)?.hasOptedOut

export const selectSubscriberStatus = (state: AppState) =>
  selectSubscriberSubscription(state)?.status || ''

export const selectSubscriberType = (state: AppState) =>
  selectSubscriberSubscription(state)?.subscriptionType

export const selectSubscriberBilling = (state: AppState) =>
  selectSubscriberSubscription(state)?.billing || {}

export const selectSubscriberAnalytics = (state: AppState) =>
  selectSubscriberSubscription(state)?.analytics || {}

export const selectIsUpdatingSubscriber = (state: AppState) => state.subscriberDetail.isUpdating
export const selectIsDeletingSubscriber = (state: AppState) => state.subscriberDetail.isDeleting
export const selectIsDowngradingSubscriber = (state: AppState) =>
  state.subscriberDetail.isDowngrading

import Head from 'next/head'
import React, { ReactNode } from 'react'

export const PageHeader = ({ name, children }: { name: string; children: ReactNode }) => {
  return (
    <>
      <Head>
        <title>Scriber | {name}</title>
      </Head>
      <div className="px-6 md:px-8 py-6 md:flex md:justify-between border-b border-gray-200">
        <p className="text-3xl text-gray-900 font-brick pb-4 md:py-0">{name}</p>
        <div className="flex flex-col md:flex-row">{children}</div>
      </div>
    </>
  )
}

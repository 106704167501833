import { createAsyncThunk } from '@reduxjs/toolkit'
import { trackPublicationUpdate } from 'helper/analytics'
import { updatePublicationMutation } from 'gql/publication/mutations'
import omitDeep from 'helper/omitDeep'
import { AppState } from 'redux/store'
import { Publication } from 'types/publication.types'
import { selectPublication } from '../selector'

export const updatePublication = createAsyncThunk(
  'auth/updatePublication',
  async (data: Partial<Publication>, { getState }) => {
    const state = getState() as AppState
    const { id } = selectPublication(state)
    const publication = await updatePublicationMutation(id, omitDeep(data))

    await trackPublicationUpdate()
    return publication
  },
)

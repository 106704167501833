import React from 'react'
import Link from 'next/link'
import classNames from 'classnames'

import { ButtonHighlight } from '@tellimer/ui/Button'
import * as Glyph from '@tellimer/ui/Icon'

const NewPostButton = ({ className, withText }: { className?: string; withText?: boolean }) => {
  const classes = {
    button: classNames(
      'px-2 py-2  lg:w-full lg:h-9 flex items-center justify-center',
      {
        'rounded-md': withText,
        'rounded-full lg:rounded-md': !withText,
      },
      className,
    ),
    label: classNames('lg:block', {
      'block ml-2': withText,
      hidden: !withText,
    }),
  }

  return (
    <Link href="/posts/new">
      <ButtonHighlight className={classes.button} type="button">
        <Glyph.Pencil className="w-4 h-4 lg:mr-2.5" title="Create New Post" />
        <span className={classes.label} aria-hidden="true">
          {` `}Create New Post
        </span>
      </ButtonHighlight>
    </Link>
  )
}

export default NewPostButton

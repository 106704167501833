import { createAsyncThunk } from '@reduxjs/toolkit'
import { analyticsQuery } from 'gql/dashboard'
import { query } from 'gql'
import { DropdownOption } from '@tellimer/ui'

/**
 * This is the action that will take a processed file and will start to get the subscribers from that
 * file into the publication.
 *
 */
export const fetchAnalytics = createAsyncThunk(
  'dashboard/fetchAnalytics',
  async ({ publicationId, duration }: { publicationId: string; duration: DropdownOption }) => {
    const timeInterval = (duration.label as string).toUpperCase().split(' ').join('_')
    const { data, error } = await query(analyticsQuery, {
      publicationId,
      timeInterval,
    })

    if (data?.analytics?.error || error) {
      throw new Error(data.analytics?.error?.message || error?.message)
    }

    const analytics = data?.analytics.data

    return {
      chartMetric: {
        ...analytics.chartMetric,
        chartData: analytics.chartMetric.chartData
          ? JSON.parse(analytics.chartMetric.chartData)
          : undefined,
      },
      keyMetrics: analytics.keyMetrics.map((metric: any) => ({
        ...metric,
        chartData: metric.chartData ? JSON.parse(metric.chartData) : undefined,
      })),
    }
  },
)

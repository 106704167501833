import { createSlice } from '@reduxjs/toolkit'

import { AvailableInterval, WhatsNext, GrowthDataFeedType } from 'types/dashboard.types'
import { DropdownOption, NotificationManager, NotificationLayout } from '@tellimer/ui'
import { fetchAnalytics } from './fetchAnalytics'
import { fetchAvailableIntervals } from './fetchAvailableIntervals'
import { fetchWhatsNext } from './fetchWhatsNext'

export type GrowthFeedsDataType = Record<GrowthDataFeedType, boolean>

export enum STATUS_FETCHING_INTERVALS {
  INIT = 'init_intervals',
  FETCHING = 'fetching_intervals',
  FETCHED = 'fetched_intervals',
  FAILED = 'fetch_failed_intervals',
}

export enum STATUS_FETCHING_ANALYTICS {
  INIT = 'init_analytics',
  FETCHING = 'fetching_analytics',
  FETCHED = 'fetched_analytics',
  FAILED = 'fetch_failed_analytics',
}

export enum STATUS_FETCHING_NEWS {
  INIT = 'init_whatsnext',
  FETCHING = 'fetching_whatsnext',
  FETCHED = 'fetched_whatsnext',
  FAILED = 'fetch_failed_whatsnext',
}

export interface PublicationState {
  featchingAvailableIntervals: boolean
  availableIntervalsStatus?: STATUS_FETCHING_INTERVALS
  availableIntervals: AvailableInterval[]
  availableIntervalsError: string | undefined
  featchingAnalytics: boolean
  analyticsStatus?: STATUS_FETCHING_ANALYTICS
  duration: DropdownOption | undefined
  growthFeed: string
  analytics: { keyMetrics: any[]; chartMetric: any }
  analyticsError: string | undefined
  featchingWhatsNext: boolean
  whatsnext?: WhatsNext
  whatsnextStatus?: STATUS_FETCHING_NEWS
  whatsnextError: string | undefined
}

export const initialState: PublicationState = {
  featchingAvailableIntervals: false,
  availableIntervalsStatus: STATUS_FETCHING_INTERVALS.INIT,
  availableIntervals: [],
  featchingAnalytics: false,
  availableIntervalsError: '',
  analyticsStatus: STATUS_FETCHING_ANALYTICS.INIT,
  duration: undefined,
  growthFeed: 'overall',
  analytics: {
    keyMetrics: [],
    chartMetric: undefined,
  },
  analyticsError: '',
  featchingWhatsNext: false,
  whatsnextStatus: STATUS_FETCHING_NEWS.INIT,
  whatsnext: undefined,
  whatsnextError: '',
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDuration: (state, action) => {
      state.duration = action.payload
    },
    setGrowthFeed: (state, action) => {
      state.growthFeed = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAvailableIntervals.fulfilled, (state, action) => {
        state.featchingAvailableIntervals = false
        state.availableIntervalsStatus = STATUS_FETCHING_INTERVALS.FETCHED
        state.availableIntervalsError = ''
        state.availableIntervals = action.payload
      })
      .addCase(fetchAvailableIntervals.rejected, (state, action) => {
        state.featchingAvailableIntervals = false
        state.availableIntervalsStatus = STATUS_FETCHING_INTERVALS.FAILED
        state.availableIntervalsError = JSON.stringify(action.error)
        NotificationManager.create({
          type: 'error',
          layout: NotificationLayout.CONDENSED,
          title: 'An error occurred while loading available intervals',
        })
      })
      .addCase(fetchAvailableIntervals.pending, state => {
        state.featchingAvailableIntervals = true
        state.availableIntervalsStatus = STATUS_FETCHING_INTERVALS.FETCHING
        state.availableIntervalsError = ''
      })
      .addCase(fetchAnalytics.fulfilled, (state, action) => {
        state.featchingAnalytics = false
        state.analyticsStatus = STATUS_FETCHING_ANALYTICS.FETCHED
        state.analyticsError = ''
        state.analytics = action.payload
      })
      .addCase(fetchAnalytics.rejected, (state, action) => {
        state.featchingAnalytics = false
        state.analyticsStatus = STATUS_FETCHING_ANALYTICS.FAILED
        state.analyticsError = JSON.stringify(action.error)
        NotificationManager.create({
          type: 'error',
          layout: NotificationLayout.CONDENSED,
          title: 'An error occurred while loading analytics',
        })
      })
      .addCase(fetchAnalytics.pending, state => {
        state.featchingAnalytics = true
        state.analyticsStatus = STATUS_FETCHING_ANALYTICS.FETCHING
        state.analyticsError = ''
      })
      .addCase(fetchWhatsNext.fulfilled, (state, action) => {
        state.featchingWhatsNext = false
        state.whatsnextStatus = STATUS_FETCHING_NEWS.FETCHED
        state.whatsnextError = ''
        state.whatsnext = action.payload
      })
      .addCase(fetchWhatsNext.rejected, (state, action) => {
        state.featchingWhatsNext = false
        state.whatsnextStatus = STATUS_FETCHING_NEWS.FAILED
        state.whatsnextError = JSON.stringify(action.error)
        NotificationManager.create({
          type: 'error',
          layout: NotificationLayout.CONDENSED,
          title: 'An error occurred while loading what`s next',
        })
      })
      .addCase(fetchWhatsNext.pending, state => {
        state.featchingWhatsNext = true
        state.whatsnextStatus = STATUS_FETCHING_NEWS.FETCHING
        state.whatsnextError = ''
      })
  },
})

export const { setDuration, setGrowthFeed } = dashboardSlice.actions
export default dashboardSlice.reducer

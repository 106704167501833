import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { handleMutationResponse } from 'gql/util'
import { ERROR_FIELDS } from '../fragments'

export const MUTATION_IMPORT_CONTACTS = gql`
  mutation importContacts ($publicationId: ID!) {
    importContacts(publicationId: $publicationId) {
      data
      ${ERROR_FIELDS}
    }
  }
`

export const importContacts = async (publicationId: string) => {
  if (!publicationId) {
    throw new Error("Can't import contacts for a publication without an id")
  }

  const res = await mutation(MUTATION_IMPORT_CONTACTS, {
    publicationId,
  })
  return handleMutationResponse(res, 'importContacts') as boolean
}

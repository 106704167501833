import { gql } from '@tellimer/graphql-client'
import {
  ERROR_FIELDS,
  SUBSCRIBER_COMMON_FIELDS,
  META_FIELDS,
  SUBSCRIPTION_COMMON_FIELDS,
} from './fragments'

export const listSubscribersQuery = gql`
  ${SUBSCRIBER_COMMON_FIELDS}
  ${SUBSCRIPTION_COMMON_FIELDS}
  query ListSubscribers($where: SubscriberListWhereInput, $sort: [SubscriberListSorter], $limit: Int!, $after: String) {
    listSubscribers(where: $where, sort: $sort, limit: $limit, after: $after) {
      data {
        id
        ...SubscriberCommonFields
        currentSubscription {
          ...SubscriptionCommonFields
        }
      }
      ${META_FIELDS}
      ${ERROR_FIELDS}
    }
  }
`

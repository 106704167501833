import { Auth } from 'aws-amplify'
import { createAsyncThunk } from '@reduxjs/toolkit'
import packageDetails from 'package.json'
import { TellimerGraphqlClient, TellimerWebSocketClient } from '@tellimer/graphql-client'
import { setClient, GQL_CLIENT_TYPES } from 'gql'
import { fetchCreator } from '../auth/actions'
import { fetchPublishedPosts } from 'redux/post/fetchPublishedPosts'
import { initImportSubscription } from 'redux/importSubscribers/initImportSubscription'
import { getTotalSubscribers } from 'redux/subscriber/getTotalSubscribers'
import { recordUserAuth } from 'helper/analytics'
import { getAuth, logout, getNewToken } from 'helper/auth/checkUserSession'

type ThunkAPI = {
  getState: CallableFunction
  dispatch: CallableFunction
}

/**
 * This is the action that will take a processed file and will start to get the subscribers from that
 * file into the publication.
 *
 */
export const initApp = createAsyncThunk('app/init', async (_, { dispatch }: ThunkAPI) => {
  let token
  try {
    const result = await getAuth()
    token = result?.token
  } catch (err) {
    await Auth.signOut()
    window.location.assign(
      process.env.NEXT_PUBLIC_IS_LOCAL_DEV
        ? 'http://localhost:3000/login'
        : process.env.NEXT_PUBLIC_AMPLIFY_OAUTH_REDIRECT_SIGN_OUT + '/login',
    )
  }

  // Now that we have an user we can initialize the gql clients so we can start doing requests
  try {
    const authCallbacks = { getAuth, logout, getNewToken }

    const client = new TellimerGraphqlClient(
      process.env.NEXT_PUBLIC_GRAPHQL_API_BASE as string,
      undefined,
      {
        'x-scriber-app-version': packageDetails.version,
        'x-scriber-app': 'CREATOR',
      },
      undefined,
      authCallbacks,
    )
    setClient(GQL_CLIENT_TYPES.PRIVATE, client.privateClient)

    const { wsPrivateClient } = new TellimerWebSocketClient(
      process.env.NEXT_PUBLIC_GRAPHQL_API_BASE as string,
      process.env.NEXT_PUBLIC_APPSYNC_REALTIME_API_ENDPOINT as string,
      token,
      authCallbacks,
    )
    setClient(GQL_CLIENT_TYPES.WS, wsPrivateClient)
    // --------
  } catch (err) {
    console.error('Failed to initialize GQL clients', err)
    throw err
  }

  // Now we fetch all the data we need to initialize the app
  try {
    const creator = await dispatch(fetchCreator()).unwrap()

    // Here we record analytics about the user authentication
    await recordUserAuth(creator)

    await Promise.all([
      //these can be kept here
      //FIXME these should not need to be fetched when based on onboarding we know the user has not published posts
      dispatch(fetchPublishedPosts()).unwrap(),

      dispatch(getTotalSubscribers()).unwrap(),

      //FIXME I wonder why we need to initialise this websocket here and whether or not there is a way to fix it now
      dispatch(initImportSubscription()).unwrap(),
    ])
  } catch (err: any) {
    if (err?.code === 'SUBSCRIBER_IS_NOT_A_CREATOR') {
      await Auth.signOut()
      window.location.assign(
        `${process.env.NEXT_PUBLIC_AMPLIFY_OAUTH_REDIRECT_SIGN_OUT || ''}/signup`,
      )
    }
    console.error('Failed to fetch initial data', err)
    throw err
  }
})

import { createAsyncThunk } from '@reduxjs/toolkit'
import { OnboardingProgress } from 'types/onboarding.types'
import { mutation } from 'gql'
import { updateOnboardingProgressMutation } from 'gql/dashboard'

export enum STATUS_UPDATING_ONBOARDING_PROGRESS {
  UPDATING = 'updating_onboarding_progress',
  UPDATED = 'updated_onboarding_progress',
  FAILED = 'update_failed',
}

export const updateUserOnboardingProgress = createAsyncThunk(
  'userOnboardingProgress/updateOnboardingProgress',
  async (progress: OnboardingProgress) => {
    await mutation(updateOnboardingProgressMutation, {
      ...flattenProgress(progress),
    })
    return progress
  },
)

const flattenProgress = (progress: OnboardingProgress) => ({
  publicationId: progress.publication?.id,
  completedPercentage: progress.completedPercentage,
  isDismissed: progress.isDismissed,
  publicationStepSkipped: progress.publicationStep.isSkipped,
  publicationStepCompleted: progress.publicationStep.isCompleted,
  brandingStepSkipped: progress.brandingStep.isSkipped,
  brandingStepCompleted: progress.brandingStep.isCompleted,
  contactsStepSkipped: progress.contactsStep.isSkipped,
  contactsStepCompleted: progress.contactsStep.isCompleted,
  verifyEmailStepSkipped: progress.verifyEmailStep.isSkipped,
  verifyEmailStepCompleted: progress.verifyEmailStep.isCompleted,
  gettingPaidStepSkipped: progress.gettingPaidStep.isSkipped,
  gettingPaidStepCompleted: progress.gettingPaidStep.isCompleted,
  publishFirstPostStepCompleted: progress.publishFirstPostStep.isCompleted,
})

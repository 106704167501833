import { AppState } from '../store'
import { ImportStatus } from './updateImportStatus'

export const selectIsImportInProgress = (state: AppState) =>
  state.importSubscribers.isImportingInProgress

export const selectImportState = (state: AppState) =>
  state.importSubscribers?.import?.status || ImportStatus.NOT_STARTED

export const selectImportDetails = (state: AppState) => state.importSubscribers?.import

import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { ERROR_FIELDS } from '../fragments'
import { handleQueryResponse } from '../../util'

export const MUTATION_POST_SEND_TEST_EMAIL = gql`
  mutation SendTestEmail($postId: String!, $email: String!) {
    sendTestEmail(postId: $postId, email: $email) {
      data
      ${ERROR_FIELDS}
    }
  }
`

export const sendPostTestEmailMutation = async (postId: string, email: string) => {
  if (!postId) {
    throw new Error("Can't send a test email without an id")
  }

  if (!email) {
    throw new Error("Can't send a test email without an email")
  }

  const res = await mutation(MUTATION_POST_SEND_TEST_EMAIL, {
    postId,
    email,
  })

  return handleQueryResponse(res, 'sendTestEmail')
}

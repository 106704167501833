import { gql } from '@tellimer/graphql-client'
import { query } from 'gql'
import { handleQueryResponse } from 'gql/util'

import { ERROR_FIELDS } from '../fragments'

export const QUERY_LIST_SUBSCRIPTION_PACKAGES = gql`
  query ListSubscriptionPackages($publicationId: String!) {
    listSubscriptionPackages(publicationId: $publicationId) {
      data {
        id
        name
        description
        isFree
        price
        isSelected
      }
      ${ERROR_FIELDS}
    }
  }
`

export const listSubscriptionPackagesQuery = async (publicationId: string) => {
  if (!publicationId) {
    throw new Error("Can't list subscription packages without a publication id")
  }

  const res = await query(QUERY_LIST_SUBSCRIPTION_PACKAGES, { publicationId })

  return handleQueryResponse(res, 'listSubscriptionPackages')
}

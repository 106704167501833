import React, { Component } from 'react'
import ErrorComponent from './ErrorComponent'

class ErrorBoundary extends Component {
  static displayName = 'ErrorBoudnary'

  static getDerivedStateFromError() {
    return {
      hasError: true,
    }
  }

  state = {
    hasError: false,
    error: null,
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error('> Unhandled rendering error', error)
    console.error('> Info:', errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorComponent />
    }

    return this.props.children
  }
}

export default ErrorBoundary

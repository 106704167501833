import { createAsyncThunk } from '@reduxjs/toolkit'
import { getSubscriberQuery } from 'gql/subscriber'
import { selectPublication } from 'redux/publication/selector'

export const fetchSubscriberDetails = createAsyncThunk(
  'subscriberDetail/fetchSubscriberDetails',
  async (id: string, { getState }) => {
    const state = getState() as any
    const publication = selectPublication(state)
    return await getSubscriberQuery(id, publication.id)
  },
)

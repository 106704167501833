import { createSlice } from '@reduxjs/toolkit'
import { PostAccess } from 'gql/post/types'

import { PostDetails, PostPreview, PostAccessKey } from 'types/post.types'

import {
  fetchPost,
  createPost,
  updatePost,
  resetPostDetail,
  startCreatePost,
  startUpdatePost,
  publishPost,
  unpublishPost,
  sendPostTestEmail,
  showPostPreview,
  hidePostPreview,
  fetchPostPreview,
  deletePost,
  setPostLoading,
  fetchPostAccessTypes,
  setPostShowPostAccessHighlight,
} from './actions'
import { PostDetailActions } from './const'

export interface IPostState {
  details: PostDetails
  preview: PostPreview
  action: PostDetailActions
  isLoading: boolean
  isSaving: boolean
  isPublishing: boolean
  isEmailing: boolean
  isLoadingPreview: boolean
  showPreview: boolean
  isDeleting: boolean
  error: any
  postAccessTypes: PostAccess[]
  isLoadingPostAccessTypes: boolean
  postAccess?: PostAccess
  defaultPostAccess: string
  showPostInterstitial?: boolean
  showPostAccessHighlight?: boolean
}

export const initialState: IPostState = {
  details: {},
  preview: {},
  action: PostDetailActions.NOT_STARTED,
  isLoading: true,
  isSaving: false,
  isPublishing: false,
  isEmailing: false,
  isLoadingPreview: false,
  showPreview: false,
  isDeleting: false,
  error: '',
  postAccessTypes: [],
  isLoadingPostAccessTypes: true,
  postAccess: undefined,
  defaultPostAccess: PostAccessKey.EVERYONE,
  showPostInterstitial: false,
  showPostAccessHighlight: false,
}

export const postDataSlice = createSlice({
  name: 'postDetail',
  initialState,
  reducers: {
    setDefaultPostAccess: (state, action) => {
      state.defaultPostAccess = action.payload
    },
  },
  extraReducers: builder => {
    builder
      // Post edit workflow actions
      .addCase(resetPostDetail, () => {
        return initialState
      })
      .addCase(setPostLoading, (state, action) => {
        state.isLoading = action.payload
      })
      .addCase(startCreatePost.fulfilled, (state, action) => {
        state.action = PostDetailActions.CREATE
        state.details = {
          ...initialState.details,
          // If create has a payload it is used as the template and loaded as the revision
          ...(action.payload || {}),
        }
        state.showPreview = false
        state.isLoading = false
      })
      .addCase(startUpdatePost, state => {
        state.action = PostDetailActions.UPDATE
        state.showPreview = false
      })

      // Create post
      .addCase(createPost.pending, state => {
        state.isSaving = true
      })
      .addCase(createPost.rejected, (state, action) => {
        state.isSaving = false
        state.error = action.payload
      })
      .addCase(createPost.fulfilled, (state, action) => {
        const details = action.payload
        state.isSaving = false
        state.details = details
      })

      // Fetch post
      .addCase(fetchPost.pending, state => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(fetchPost.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload
        state.details = {}
      })
      .addCase(fetchPost.fulfilled, (state, action) => {
        const details = action.payload
        state.isLoading = false
        state.error = ''
        state.details = details
      })

      // Update post
      .addCase(updatePost.pending, state => {
        state.isSaving = true
      })
      .addCase(updatePost.rejected, (state, action) => {
        state.isSaving = false
        state.error = action.payload
      })
      .addCase(updatePost.fulfilled, (state, action) => {
        state.isSaving = false
        state.details = action.payload
      })

      // Publish post
      .addCase(publishPost.pending, state => {
        state.isPublishing = true
      })
      .addCase(publishPost.rejected, (state, action) => {
        state.isPublishing = false
        state.error = action.payload
      })
      .addCase(publishPost.fulfilled, state => {
        state.isPublishing = false
        state.details.isPublished = true
      })

      // Unpublish the post
      .addCase(unpublishPost.pending, state => {
        state.isPublishing = true
      })
      .addCase(unpublishPost.rejected, (state, action) => {
        state.isPublishing = false
        state.error = action.payload
      })
      .addCase(unpublishPost.fulfilled, state => {
        state.isPublishing = false
        state.details.isPublished = false
      })

      // Send test email
      .addCase(sendPostTestEmail.pending, state => {
        state.isEmailing = true
      })
      .addCase(sendPostTestEmail.rejected, (state, action) => {
        state.isEmailing = false
        state.error = action.payload
      })
      .addCase(sendPostTestEmail.fulfilled, state => {
        state.isEmailing = false
      })

      // Post preview
      .addCase(showPostPreview, state => {
        state.showPreview = true
      })
      .addCase(hidePostPreview, state => {
        state.showPreview = false
        state.isLoadingPreview = false
      })
      .addCase(fetchPostPreview.pending, state => {
        state.isLoadingPreview = true
      })
      .addCase(fetchPostPreview.rejected, (state, action) => {
        state.isLoadingPreview = false
        state.error = action.payload
      })
      .addCase(fetchPostPreview.fulfilled, (state, action) => {
        state.isLoadingPreview = false
        state.preview = action.payload
      })

      // Delete post
      .addCase(deletePost.pending, state => {
        state.isDeleting = true
      })
      .addCase(deletePost.rejected, (state, action) => {
        state.isDeleting = true
        state.error = action.payload
      })
      .addCase(deletePost.fulfilled, () => {
        // After deleting we basically reset everything, the UI should redirect us outside
        // the post screen
        return initialState
      })

      // Fetch access types
      .addCase(fetchPostAccessTypes.pending, state => {
        state.isLoadingPostAccessTypes = true
      })
      .addCase(fetchPostAccessTypes.rejected, (state, action) => {
        state.error = action.payload
        state.isLoadingPostAccessTypes = false
      })
      .addCase(fetchPostAccessTypes.fulfilled, (state, action) => {
        state.postAccessTypes = action.payload
        state.isLoadingPostAccessTypes = false
      })

      // Set the flag for post access highlight
      .addCase(setPostShowPostAccessHighlight, (state, action) => {
        state.showPostAccessHighlight = action.payload
      })
  },
})

export const { setDefaultPostAccess } = postDataSlice.actions
export default postDataSlice.reducer

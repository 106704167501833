import { PostAccess } from 'gql/post/types'

export type PostMetrics = {
  opens: string
  openPercentage: string
  clickPercentage: string
  unsubscribePercentage: string
  webViews: string
}

export type PostPreview = {
  emailHtml?: string
  webUrl?: string
}

export type PostDetails = {
  id?: string
  isNew?: boolean
  isPublished?: boolean
  image?: File
  imageCaption?: string
  subject?: string
  url?: string
  title?: string
  subtitle?: string
  slug?: string
  body?: any
  featuredImageCaption?: string
  featuredImage?: string
  featuredImagePresence?: FeaturedImagePresence
  emailSubject?: string
  readTime?: number
  preview?: PostPreview
  metrics?: PostMetrics
  emailRecipientCount?: number
  createdOn?: string
  publishedAt?: string
  publishTo?: PublishTo
  metaDescription?: string
  useMetaDescription?: boolean
  summary?: string
  postAccess?: PostAccess
}

export type PostFilterParams = {
  title_contains?: string
  isPublished?: boolean
}

export enum PublishTo {
  WEBSITE_AND_EMAIL = 'all',
  WEBSITE_ONLY = 'web',
  EMAIL_ONLY = 'email',
}

export enum FeaturedImagePresence {
  NOT_SET = 'NOT_SET',
  SHOW = 'SHOW',
  HIDE = 'HIDE',
}

export enum PostSort {
  LATEST_PUBLISHED = 'publishedAt_DESC',
  NEWEST = 'createdOn_DESC',
  OLDEST = 'createdOn_ASC',
  LAST_UPDATED = 'updatedAt_DESC',
  ALPHABETICAL = 'title_ASC',
  READ = 'readCount_DESC',
}

export type PostInput = {
  id?: string
  subject?: string
  url?: string
  title?: string
  subtitle?: string
  slug?: string
  body?: any
  featuredImagePresence?: FeaturedImagePresence
  featuredImageCaption?: string
  featuredImage?: string
  emailSubject?: string
  useMetaDescription?: boolean
  metaDescription?: string
  summary?: string
  postAccessId?: string
}

export type Post = {
  id?: string
  createdOn?: string
  savedOn?: string
  image?: File
  imageCaption?: string
  subject?: string
  url?: string
  title?: string
  subtitle?: string
  slug?: string
  metrics?: PostMetrics
  body?: any
  featuredImage?: string
  emailSubject?: string
  publishedAt?: string
  isNew?: boolean
  featuredImageCaption?: string
  readTime?: number
  preview?: PostPreview
  publishTo: PublishTo
  emailRecipientCount?: number
  isPublished?: boolean
  metaDescription?: string
  summary?: string
  postAccess?: PostAccess
}

export type PostWhereInput = {
  publication?: string
  slug?: string
}

export enum PostAccessKey {
  EVERYONE = 'EVERYONE',
  FREE = 'FREE',
  PAID = 'PAID',
}

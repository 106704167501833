import React from 'react'
import * as Glyph from '@tellimer/ui/Icon'
import ProfileMenu from './ProfileMenu'

const Footer: React.FC = () => {
  return (
    <div className="flex flex-col w-full lg:absolute bottom-0 bg-gray-700">
      <div className="hidden lg:block">
        <ProfileMenu position="top" />
      </div>
      <div className="sidebar-items-divider" />
      <div className="px-4 py-6 flex items-center">
        <span className="text-xs text-gray-400 font-medium">Powered by</span>
        <Glyph.LogoWithText className="h-5 text-gray-400" title="Scriber" />
      </div>
    </div>
  )
}

export default Footer

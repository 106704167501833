export type SearchResult = {
  id: string
  url: string
  type: SearchResultType
  title: string
}

export enum SearchResultType {
  POST = 'POST',
  SUBSCRIBER = 'SUBSCRIBER',
}

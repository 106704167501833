import { createAsyncThunk } from '@reduxjs/toolkit'
import { listPostAccessTypesQuery } from 'gql/post/queries'
import { getCreator } from 'redux/auth/selector'
import { Creator } from 'types/creator.types'

export const fetchPostAccessTypes = createAsyncThunk(
  'postDetail/fetchPostAccessTypes',
  async (_, { getState }) => {
    const state = getState() as any
    const creator = getCreator(state) as Creator
    try {
      const result = await listPostAccessTypesQuery(creator.publication.id)
      return result || []
    } catch (e) {
      console.error(e)
      throw e
    }
  },
)

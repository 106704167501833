import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { handleMutationResponse } from 'gql/util'
import { SubscriptionUpdated } from 'types/subscriber.types'
import { ERROR_FIELDS } from '../common'

export const MUTATION_UNSUBSCRIBE_BY_EMAIL = gql`
  mutation unsubscribeByEmail($publicationId: ID!, $email: String!) {
    unsubscribeByEmail(publicationId: $publicationId, email:$email) {
      data {
        isConfirmed
        isSubscribed
        confirmedAt
      }
      ${ERROR_FIELDS}
    }
  }
`

export type UnsubscribeByEmailVariablesType = {
  publicationId: string
  email: String
}

export const unsubscribeByEmailMutation = async (variables: UnsubscribeByEmailVariablesType) => {
  if (!variables) throw new Error("Can't unsubscribe without variables")
  if (!variables.publicationId) throw new Error("Can't unsubscribe without publication id")
  if (!variables.email) throw new Error("Can't unsubscribe without email")

  const response = await mutation(MUTATION_UNSUBSCRIBE_BY_EMAIL, variables)
  return handleMutationResponse(response, 'unsubscribeByEmail') as SubscriptionUpdated
}

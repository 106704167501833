import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  OnboardingProgress,
  OnboardingStepTypes,
  OnboardingProgressFromSubscription,
  OnboardingProgressStep,
} from 'types/onboarding.types'

import { NotificationLayout, NotificationManager } from '@tellimer/ui'

import { sendVerificationEmail } from './sendVerificationEmail'
import { fetchOnboardingProgress } from './fetchOnboardingProgress'
import { updateUserOnboardingProgress } from './updateOnboardingProgress'
import { dismissAccountSetup } from './dismissAccountSetup'
import { completeOnboardingStep } from './completeOnboardingStep'

export const ONBOARDING_STEP_SEQUENCE = [
  OnboardingStepTypes.PUBLICATION,
  OnboardingStepTypes.BRANDING,
  OnboardingStepTypes.CONTACTS,
  OnboardingStepTypes.EMAIL,
  OnboardingStepTypes.PAYMENT,
  OnboardingStepTypes.POST,
]

export interface OnboardingProgressState {
  progress: OnboardingProgress
  isLoading: boolean
  error: string
  currentStep: OnboardingStepTypes
  isSendingVerifyEmail: boolean
}

export type OnbardingStepUpdateType = OnboardingProgressStep & {
  step: OnboardingStepTypes
}

export const initialState: OnboardingProgressState = {
  progress: {
    isDismissed: false,
    completedPercentage: '0%',
    [OnboardingStepTypes.PUBLICATION]: {
      isSkipped: false,
      isCompleted: false,
    },
    [OnboardingStepTypes.BRANDING]: {
      isSkipped: false,
      isCompleted: false,
    },
    [OnboardingStepTypes.CONTACTS]: {
      isSkipped: false,
      isCompleted: false,
    },
    [OnboardingStepTypes.EMAIL]: {
      isSkipped: false,
      isCompleted: false,
    },
    [OnboardingStepTypes.PAYMENT]: {
      isSkipped: false,
      isCompleted: false,
    },
    [OnboardingStepTypes.POST]: {
      isCompleted: false,
    },
  },
  currentStep: OnboardingStepTypes.PUBLICATION,
  isLoading: false,
  isSendingVerifyEmail: false,
  error: '',
}

const getOnboardingNextStep = (currentStep: OnboardingStepTypes, progress: OnboardingProgress) => {
  const currentPosition = ONBOARDING_STEP_SEQUENCE.indexOf(currentStep)

  if (currentPosition < 0 || !progress) {
    return OnboardingStepTypes.PUBLICATION
  }

  return (
    ONBOARDING_STEP_SEQUENCE.slice(currentPosition).find(
      key => !progress[key]?.isCompleted && !progress[key]?.isSkipped,
    ) || OnboardingStepTypes.POST
  )
}

export const onboardingProgressSlice = createSlice({
  name: 'userOnboardingProgress',
  initialState,
  reducers: {
    setOnboardingCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep =
        ONBOARDING_STEP_SEQUENCE[action.payload] || OnboardingStepTypes.PUBLICATION
    },
    setOnboardingProgress: (state, action: PayloadAction<OnboardingProgress>) => {
      state.progress = { ...state.progress, ...action.payload }
    },
    updateOnboardingProgressFromSubscription: (
      state,
      action: PayloadAction<OnboardingProgressFromSubscription>,
    ) => {
      const { payload } = action

      state.progress = {
        ...state.progress,
        completedPercentage: payload.completedPercentage,
        isDismissed: payload.isDismissed,
        publicationStep: {
          isSkipped: !!payload.publicationStepSkipped,
          isCompleted: !!payload.publicationStepCompleted,
        },
        brandingStep: {
          isSkipped: !!payload.brandingStepSkipped,
          isCompleted: !!payload.brandingStepCompleted,
        },
        contactsStep: {
          isSkipped: !!payload.contactsStepSkipped,
          isCompleted: !!payload.contactsStepCompleted,
        },
        verifyEmailStep: {
          isSkipped: !!payload.verifyEmailStepSkipped,
          isCompleted: !!payload.verifyEmailStepCompleted,
        },
        gettingPaidStep: {
          isSkipped: !!payload.gettingPaidStepSkipped,
          isCompleted: !!payload.gettingPaidStepCompleted,
        },
        publishFirstPostStep: {
          isCompleted: !!payload.publishFirstPostStepCompleted,
        },
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchOnboardingProgress.pending, state => {
        state.error = ''
        state.isLoading = true
      })
      .addCase(fetchOnboardingProgress.fulfilled, (state, action) => {
        const { stepId, ...progress } = action.payload || {}

        state.error = ''
        state.isLoading = false
        state.progress = progress
        state.currentStep = stepId || getOnboardingNextStep(state.currentStep, state.progress)
      })
      .addCase(fetchOnboardingProgress.rejected, (state, action) => {
        state.error = JSON.stringify(action.error)
        state.isLoading = false
      })
      .addCase(updateUserOnboardingProgress.fulfilled, (state, action) => {
        state.progress = action.payload
        state.currentStep = getOnboardingNextStep(state.currentStep, state.progress)
      })
      .addCase(updateUserOnboardingProgress.rejected, (state, action) => {
        state.error = JSON.stringify(action.error)
      })
      .addCase(dismissAccountSetup.fulfilled, state => {
        state.progress.isDismissed = true
      })
      .addCase(sendVerificationEmail.pending, state => {
        state.isSendingVerifyEmail = true
      })
      .addCase(sendVerificationEmail.fulfilled, state => {
        state.isSendingVerifyEmail = false
      })
      .addCase(sendVerificationEmail.rejected, state => {
        state.isSendingVerifyEmail = false
      })
      .addCase(completeOnboardingStep.fulfilled, (state, action) => {
        const { step, data } = action.payload
        state.progress[step] = data
        state.currentStep = getOnboardingNextStep(step, state.progress)
      })
      .addCase(completeOnboardingStep.rejected, (state, action) => {
        state.error = JSON.stringify(action.error)
        state.isLoading = false

        console.error(action.error)
        NotificationManager.create({
          type: 'error',
          layout: NotificationLayout.CONDENSED,
          title: 'An error ocurred while completing the onboarding step',
        })
      })
  },
})

export { fetchOnboardingProgress } from './fetchOnboardingProgress'
export { updateUserOnboardingProgress } from './updateOnboardingProgress'
export { sendVerificationEmail } from './sendVerificationEmail'
export { completeOnboardingStep } from './completeOnboardingStep'
export const {
  setOnboardingProgress,
  setOnboardingCurrentStep,
  updateOnboardingProgressFromSubscription,
} = onboardingProgressSlice.actions
export default onboardingProgressSlice.reducer

import { createSlice } from '@reduxjs/toolkit'
import { initApp } from './initApp'
import { NotificationManager, NotificationLayout } from '@tellimer/ui/Notification'

export interface AppState {
  loaded: boolean
  failed: boolean
  showViewError: boolean
}

export const initialState: AppState = {
  loaded: false,
  failed: false,
  showViewError: false,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setFatalError(state) {
      state.failed = true
    },
  },
  extraReducers: builder => {
    builder
      .addCase(initApp.pending, state => {
        state.loaded = false
      })
      .addCase(initApp.rejected, (state, action) => {
        state.loaded = true
        state.failed = true
        console.error(action.error)
        NotificationManager.create({
          type: 'error',
          layout: NotificationLayout.CONDENSED,
          title: 'An error occurred while initializing the app',
        })
      })
      .addCase(initApp.fulfilled, state => {
        state.loaded = true
      })
  },
})

export const { setFatalError } = appSlice.actions
export default appSlice.reducer

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Onboarding } from 'types/onboarding.types'

import { fetchOnboarding } from './fetchOnboarding'

export interface OnboardingState {
  info?: Onboarding
  error: string
  isLoading: boolean
}

export const initialState: OnboardingState = {
  info: undefined,
  isLoading: false,
  error: '',
}

export const dashboardSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setOnboarding: (state, action: PayloadAction<Onboarding>) => {
      state.info = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchOnboarding.pending, state => {
        state.error = ''
        state.isLoading = true
      })
      .addCase(fetchOnboarding.fulfilled, (state, action) => {
        state.error = ''
        state.info = action.payload
        state.isLoading = false
      })
      .addCase(fetchOnboarding.rejected, (state, action) => {
        state.error = JSON.stringify(action.error)
        state.isLoading = false
      })
  },
})

export const { setOnboarding } = dashboardSlice.actions
export default dashboardSlice.reducer

import { NotificationLayout, NotificationManager } from '@tellimer/ui'
import { PostInput } from 'types/post.types'
import { createPost } from './createPost'
import { selectPostDetails } from '../selector'
import { updatePost } from './updatePost'
import createDebouncedAsyncThunk from 'helper/createDebouncedAsyncThunk'

export const savePost = createDebouncedAsyncThunk(
  'postDetail/savePost',
  async (payload: PostInput, { dispatch, getState }: { dispatch: any; getState: any }) => {
    try {
      const state = getState() as any
      const post = selectPostDetails(state)

      if (!post.id) {
        return await dispatch(createPost(payload)).unwrap()
      }

      return await dispatch(updatePost(payload)).unwrap()
    } catch (e) {
      console.error(e)
      NotificationManager.create({
        type: 'error',
        layout: NotificationLayout.SIMPLE,
        title: 'Error',
        text: 'Something failed while saving the post',
      })
      throw e
    }
  },
  300,
)

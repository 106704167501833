import { createAsyncThunk } from '@reduxjs/toolkit'

import { PAGE_SIZE } from 'helper/constants'
import { PostSort, PostFilterParams } from 'types/post.types'
import { fetchPosts } from './fetchPosts'

export type FetchPagePostsType = {
  sort: PostSort
  filterParams?: PostFilterParams
  page: number
}

export const fetchPagePosts = createAsyncThunk(
  'posts/fetchPage',
  async (payload: FetchPagePostsType, { getState, dispatch }) => {
    const { page, sort, filterParams } = payload
    const state = getState() as any

    if (page >= Math.ceil(state.post.posts.length / PAGE_SIZE)) {
      const after = state.post.cursor
      try {
        await dispatch(fetchPosts({ sort, filterParams, after, loadMore: true }))
      } catch (e) {
        throw e
      }
    }

    return page
  },
)

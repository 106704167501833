import { gql } from '@tellimer/graphql-client'
import { query } from 'gql'
import { handleQueryResponse } from 'gql/util'

import { ERROR_FIELDS } from '../fragments'

export const QUERY_LIST_SUBSCRIPTION_TYPES = gql`
  query ListSubscriptionTypes($publicationId: ID!) {
    listSubscriptionTypes(publicationId: $publicationId) {
      data {
        name
        description
        isFree
        isEnabled
        isRecommended
        hasDiscount
        type
        price
        discounts {
          value
          name
          isSelected
          isCustom
        }
      }
      ${ERROR_FIELDS}
    }
  }
`

export const listSubscriptionTypesQuery = async (publicationId: string) => {
  if (!publicationId) {
    throw new Error("Can't list subscription types without a publication id")
  }

  const res = await query(QUERY_LIST_SUBSCRIPTION_TYPES, { publicationId })

  return handleQueryResponse(res, 'listSubscriptionTypes')
}

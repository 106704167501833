import React, { Fragment, useState } from 'react'
import { Auth } from 'aws-amplify'
import classNames from 'classnames'
import Link from 'next/link'

// redux
import { getCreator } from 'redux/auth/selector'
import { selectPublication } from 'redux/publication/selector'

// hooks
import { useAppSelector } from 'hooks/reduxHooks'

import { Menu, Transition } from '@tellimer/ui/headless'
import { Avatar } from '@tellimer/ui/Avatar'
import * as Glyph from '@tellimer/ui/Icon'
import { resetUser } from 'helper/analytics'

type menuItemsType = {
  title?: string
  href?: string
  target?: string
  rel?: string
  divider?: boolean
  onClick?: () => void
}[]

export type ProfileMenuProps = {
  position?: 'bottom' | 'top'
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({ position = 'bottom' }) => {
  const creator = useAppSelector(getCreator)
  const publication = useAppSelector(selectPublication)
  const [showProfileMenu, setShowProfileMenu] = useState(false)

  const menuItems: menuItemsType = [
    { divider: true },
    {
      title: 'View website',
      href: publication?.domain?.name ? `https://${publication?.domain?.name}.scriber.to` : '/',
      target: '_blank',
      rel: 'noreferrer',
    },
    {
      title: 'Your profile',
      href: '/settings/account',
    },
    { divider: true },
    {
      title: 'Support',
      href: 'mailto: support@scriber.to',
    },
    {
      title: 'Resources',
      href: 'https://tellimer.notion.site/Scriber-Resources-901d064df24942a5a99aafcae5246525',
      target: '_blank',
      rel: 'noreferrer',
    },
    { divider: true },
    {
      onClick: handleLogOutClick,
      title: 'Log out',
    },
  ]

  const classes = {
    menuItems: classNames({
      'bottom-[90%] origin-bottom-left': position === 'top',
      'top-14 origin-top-left ': position === 'bottom',
      'z-50 w-56 absolute left-4 pb-1 z-10 rounded-md overflow-hidden bg-white ring-1 ring-black ring-opacity-5 shadow-lg focus:outline-none':
        true,
    }),
  }

  function closeProfileMenu() {
    setShowProfileMenu(false)
  }

  function toggleProfileMenu() {
    setShowProfileMenu(showMenu => !showMenu)
  }

  async function handleLogOutClick() {
    await Auth.signOut()

    resetUser()

    //if it needs more than oauth redirect to Explorer
    window.location.assign(process.env.NEXT_PUBLIC_AMPLIFY_OAUTH_REDIRECT_SIGN_OUT || '/')
  }

  return (
    <Menu as="div" className="relative text-left">
      <button
        type="button"
        className="grid grid-cols-12 items-center w-full p-4"
        onClick={toggleProfileMenu}
      >
        <div className="flex items-center col-span-11">
          <Avatar className="flex-shrink-0 lg:mr-3" url={creator?.profilePicture} />
          <span className="hidden lg:block lg:mr-6 text-left truncate text-sm font-medium text-white">
            {creator?.fullName || creator?.email || ''}
          </span>
        </div>
        <Glyph.Selector
          className="hidden lg:block col-span-1 h-5 text-gray-400"
          title="Toggle menu"
        />
      </button>

      <Transition
        as={Fragment}
        show={showProfileMenu}
        enter="transform transition ease-out duration-100"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transform transition ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Menu.Items className={classes.menuItems} static>
          <Menu.Item>
            <div className="text-left px-4 py-3">
              <span className="block text-sm text-gray-900 font-medium truncate">
                {creator?.fullName || ''}
              </span>
              <p className="text-sm text-gray-900 truncate">{creator?.email || ''}</p>
            </div>
          </Menu.Item>

          {menuItems.map(({ title, divider, href = '', ...rest }, index) => {
            if (divider) {
              return (
                <div
                  key={index}
                  className={`profile-menu-divider ${index === 0 ? 'mb-1' : 'my-1'}`}
                />
              )
            }

            return (
              <Menu.Item as={Link} key={index} href={href} passHref>
                <a
                  className="w-full block cursor-pointer text-sm text-gray-700 text-left px-4 py-2 hover:bg-gray-100 hover:text-gray-900"
                  onClick={closeProfileMenu}
                  {...rest}
                >
                  {title}
                </a>
              </Menu.Item>
            )
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default ProfileMenu

import { gql } from '@tellimer/graphql-client'
import { query } from 'gql'
import { handleQueryResponse } from 'gql/util'
import { Creator } from 'types/creator.types'
import { ERROR_FIELDS } from '../fragments'

export const QUERY_GET_CREATOR = gql`
  query GetCreator {
    creator: getCreator {
      data {
        id
        userId
        fullName
        email
        canChangePassword
        hasRandomPassword
        canDeleteAccount
        canChangeEmail
        profilePicture
        hasClickedAddContentButton
        showPostAccessHighlight
        hasCompletedOnboarding
        hasVerifiedEmail
        showPostInterstitialPage
        defaultPostAccessType {
          name
          id
          hasPaywall
          isDefault
        }
        publication: currentPublication {
          id
          name
          domain {
            name
            isSubdomain
          }
          customisation {
            accentColor
            logo
          }

          defaultEmailList {
            id
            file {
              status
              totalContactsToImport
              totalSkippedContacts
              totalImportedContacts
            }
          }
          hasPaidPackage
        }
      }
      ${ERROR_FIELDS}
    }
  }
`

export const getCreatorQuery = async () => {
  const res = await query(QUERY_GET_CREATOR)
  return handleQueryResponse(res, 'creator') as Creator
}

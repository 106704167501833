import { createAsyncThunk } from '@reduxjs/toolkit'
import { query } from 'gql'
import { availableIntervalsQuery } from 'gql/dashboard'
import { AvailableIntervalSort, FetchAnalyticsType } from 'types/dashboard.types'
import { PAGE_SIZE } from 'helper/constants'

/**
 * This is the action that will take a processed file and will start to get the subscribers from that
 * file into the publication.
 *
 */
export const fetchAvailableIntervals = createAsyncThunk(
  'dashboard/fetchAvailableIntervals',
  async (payload: FetchAnalyticsType, { getState }) => {
    const state = getState() as any
    const {
      sort = state.auth.sort || AvailableIntervalSort.NEWEST,
      filterParams,
      limit = PAGE_SIZE,
      after,
    } = payload

    const where = { ...(filterParams || {}) }
    const result = await query(availableIntervalsQuery, {
      sort: [sort],
      limit: limit,
      after,
      where,
    })

    if (result?.data?.listAvailableIntervals?.error) {
      throw new Error(result?.data?.listAvailableIntervals?.error)
    }

    return result?.data?.listAvailableIntervals.data
  },
)

import { createAsyncThunk } from '@reduxjs/toolkit'
import { NotificationLayout, NotificationManager } from '@tellimer/ui'
import { sendVerificationEmail as verifyEmailMutation } from 'gql/creator'
import { mutation } from 'gql'

export const sendVerificationEmail = createAsyncThunk(
  'onboardingProgress/sendVerificationEmail',
  async () => {
    const res = await mutation(verifyEmailMutation)

    if (res.error) {
      throw new Error(res.error as any)
    }

    if (res.data.sendVerificationEmail.error) {
      throw new Error(res.data.sendVerificationEmail.error)
    }

    NotificationManager.create({
      type: 'success',
      layout: NotificationLayout.CONDENSED,
      title: 'Verification email sent',
    })
  },
)

import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { ERROR_FIELDS } from '../fragments'
import { handleQueryResponse } from '../../util'

export const MUTATION_POST_DELETE = gql`
  mutation DeletePost($id: ID!) {
    deletePost(id: $id) {
      data
      ${ERROR_FIELDS}
    }
  }
`

export const deletePostMutation = async (id: string) => {
  if (!id) {
    throw new Error("Can't delete a post without an id")
  }

  const res = await mutation(MUTATION_POST_DELETE, {
    id,
  })

  return handleQueryResponse(res, 'deletePost')
}

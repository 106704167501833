import { PostSort, PostAccessKey } from 'types/post.types'
import { SubscriberSort } from 'types/subscriber.types'
import { AccessEveryone, AccessPaid, AccessFree } from '@tellimer/ui'

export const POST_SORT_OPTIONS = [
  { value: PostSort.LAST_UPDATED, label: 'Last updated', slug: 'latest' },
  { value: PostSort.LATEST_PUBLISHED, label: 'Last published', slug: 'published' },
  { value: PostSort.ALPHABETICAL, label: 'Alphabetical', slug: 'alphabetical' },
  { value: PostSort.NEWEST, label: 'Newest', slug: 'newest' },
  { value: PostSort.OLDEST, label: 'Oldest', slug: 'oldest' },
  { value: PostSort.READ, label: 'Most read', slug: 'most-read' },
]

export const SUBSCRIBER_SORT_OPTIONS = [
  { value: SubscriberSort.NEWEST, label: 'Newest' },
  { value: SubscriberSort.EMAIL, label: 'Email' },
  { value: SubscriberSort.NAME, label: 'Name' },
  // { value: 'type', label: 'Type', disabled: true },
]

export const PAGE_SIZE = 10
export const APP_NAME = 'Scriber'

export const POST_ACCESS_TYPE_OPTIONS = [
  {
    value: PostAccessKey.EVERYONE,
    label: 'Everyone (Free and Paid)',
    description:
      'Your post will be emailed to both Paid and Free subscribers and visible to everyone online.',
    iconElem: AccessEveryone,
  },
  {
    value: PostAccessKey.FREE,
    label: 'Free subscribers only',
    description:
      'Your post will be emailed to your Free subscribers only and visible to everyone on your website.',
    iconElem: AccessFree,
  },
  {
    value: PostAccessKey.PAID,
    label: 'Paid subscribers only',
    description:
      'Your post will only be emailed and available to your paying subscribers, with a paywalled version for the public.',
    iconElem: AccessPaid,
  },
]

import { gql } from '@tellimer/graphql-client'
import { query } from 'gql'
import { PostPreview } from 'types/post.types'
import { ERROR_FIELDS } from '../fragments'
import { handleQueryResponse } from '../../util'

export const QUERY_GET_POST_PREVIEW = gql`
  query GetPost($id: ID) {
    getPost(id: $id) {
      data {
        id
        preview {
          emailHtml
          webUrl
        }
      }
      ${ERROR_FIELDS}
    }
  }
`

export const getPostPreviewQuery = async (id: string) => {
  if (!id) {
    throw new Error("Can't get post details without an id")
  }

  const res = await query(QUERY_GET_POST_PREVIEW, { id })

  const { preview } = handleQueryResponse(res, 'getPost')
  return preview as PostPreview
}

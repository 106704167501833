import { gql } from '@tellimer/graphql-client'
import { ERROR_FIELDS, META_FIELDS } from 'gql/common'

export const searchQuery = gql`
  query SearchQuery($publicationId: String!, $query: String!, $after: String, $limit: Int) {
    search(
      searchInput: {
        after: $after
        limit: $limit
        where: { publicationId: $publicationId, query: $query }
      }
    ) {
      data {
        id
        url
        type
        title
      }
      ${META_FIELDS}
      ${ERROR_FIELDS}
    }
  }
`

import { createAsyncThunk } from '@reduxjs/toolkit'
import { sendPostTestEmailMutation } from 'gql/post/mutations'

export const sendPostTestEmail = createAsyncThunk(
  'postDetail/sendPostTestEmail',
  async ({ postId, email }: { postId: string; email: string }) => {
    try {
      await sendPostTestEmailMutation(postId, email)
    } catch (e) {
      throw e
    }
  },
)

import { createAsyncThunk } from '@reduxjs/toolkit'
import { listSubscriberIdsQuery } from 'gql/subscriber/queries'
import { selectCreator } from 'redux/auth/selector'

export const getTotalSubscribers = createAsyncThunk(
  'subscribers/getTotalSubscribers',
  async (_, { getState }) => {
    const state = getState() as any
    const creator = selectCreator(state)

    const where = { publicationId: creator.publication?.id }

    const { meta } = await listSubscriberIdsQuery({ where })

    return meta.totalCount
  },
)

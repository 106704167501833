import { gql } from '@tellimer/graphql-client'
import { ERROR_FIELDS, ONBOARDING_PROGRESS_COMMON_FIELDS } from './fragments'

export const listOnboardingProgressQuery = gql`
  query ListOnboardingProgress($publicationId: ID!) {
    listOnboardingProgress(publicationId: $publicationId) {
      data {
        ...OnboardingProgressCommonFields
      }
      ${ERROR_FIELDS}
    }
  }
  ${ONBOARDING_PROGRESS_COMMON_FIELDS}
`

import React from 'react'

const ErrorComponent = () => (
  <div className="w-full min-h-screen flex justify-center items-center">
    <div className="flex flex-col justify-center items-center px-8">
      <h1 className="text-5xl leading-none font-normal text-gray-900 mb-4">
        Oops, we're having some issues
      </h1>
      <p className="text-xl leading-7 font-normal text-gray-700 mb-4">
        Sorry, we’re having some trouble loading your data.
      </p>
      <a
        href="mailto:support@scriber.to"
        className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-accent-600 focus:ring-accent-500 over:bg-accent-700"
      >
        Contact support
      </a>
    </div>
  </div>
)

export default ErrorComponent

import { gql } from '@tellimer/graphql-client'
import { query } from 'gql'
import { PostSort, PostWhereInput } from 'types/post.types'
import { ERROR_FIELDS, POST_COMMON_FIELDS, META_FIELDS } from '../fragments'
import { handleQueryResponseWithMeta } from 'gql/util'
import { ListPostData } from '../types'

export const QUERY_LIST_POSTS = gql`
  ${POST_COMMON_FIELDS}
  query ListPosts($where: PostListWhereInput, $sort: [PostListSorter], $limit: Int!, $after: String) {
    listPosts(where: $where, sort: $sort, limit: $limit, after: $after) {
      data {
        id
        ...PostCommonFields
      }
      ${META_FIELDS}
      ${ERROR_FIELDS}
    }
  }
`
export type ListPostVariablesType = {
  sort?: [PostSort]
  where?: PostWhereInput
  limit?: number
  after?: string
}

export const listPostsQuery = async (variables: any) => {
  if (!variables) throw new Error("Can't get post list without variables")

  const response = await query(QUERY_LIST_POSTS, variables)

  return handleQueryResponseWithMeta(response, 'listPosts') as ListPostData
}

import { updateUser } from './scriberAnalyticsApi'

export const trackPublicationUpdate = async () => {
  await updateUser({
    traits: {
      scriber_publication_setup: true,
    },
    type: 'publication-update',
  })
}

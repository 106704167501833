import { Auth } from 'aws-amplify'
import { useState, useEffect } from 'react'

export type User = {
  email: string
  name?: string
  picture?: string
}

export const useUser = () => {
  const [user, setUser] = useState<User>()

  useEffect(() => {
    const handleUser = async () => {
      try {
        const { attributes } = await Auth.currentAuthenticatedUser()
        const { email, name, picture } = attributes
        setUser({ email, name, picture })
      } catch (error) {
        // NOTE: WE don't want to log anything here since Auth will throw for non authenticated users
        //      Since this can be invoked in situations where users are not logged in (subscribe and auth)
        //      we don't really want to pollute the logs with errors that are not
        // console.error(error)
      }
    }
    handleUser()
  }, [])

  return user
}

import { Publication } from './publication.types'

export enum StepperType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum OnboardingStepTypes {
  PUBLICATION = 'publicationStep',
  BRANDING = 'brandingStep',
  CONTACTS = 'contactsStep',
  EMAIL = 'verifyEmailStep',
  POST = 'publishFirstPostStep',
  PAYMENT = 'gettingPaidStep',
}

export type OnboardingStep = {
  weight: string
  timeToFillOut: string
}

export type OnboardingPublicationStep = OnboardingStep & {
  publicationField: {
    isNameRequired: boolean
    isDescriptionRequired: boolean
    isDomainRequired: boolean
  }
}

export type OnboardingBrandingStep = OnboardingStep & {
  customisationField: {
    isAccentColorRequired: boolean
    isLogoRequired: boolean
  }
}

export type OnboardingContactsStep = OnboardingStep & {
  subscribersField: {
    isNameRequired: boolean
    isEmailRequired: boolean
  }
}

export type Onboarding = {
  publicationId?: string
  isDismissed?: boolean
  [OnboardingStepTypes.PUBLICATION]?: OnboardingPublicationStep
  [OnboardingStepTypes.BRANDING]?: OnboardingBrandingStep
  [OnboardingStepTypes.CONTACTS]?: OnboardingContactsStep
  [OnboardingStepTypes.EMAIL]?: OnboardingStep
  [OnboardingStepTypes.POST]?: OnboardingStep
  [OnboardingStepTypes.PAYMENT]?: OnboardingStep
}

export type OnboardingProgressStep = {
  isCompleted: boolean
  isSkipped?: boolean
}

export type OnboardingProgressPublicationStep = OnboardingProgressStep
export type OnboardingProgressBrandingStep = OnboardingProgressStep
export type OnboardingProgressContactsStep = OnboardingProgressStep
export type OnboardingProgressEmailStep = OnboardingProgressStep
export type OnboardingProgressPaymentStep = OnboardingProgressStep
export type OnboardingProgressFirstPostStep = OnboardingProgressStep

export type OnboardingProgress = {
  publication?: Publication
  isDismissed: boolean
  completedPercentage: string
  [OnboardingStepTypes.PUBLICATION]: OnboardingProgressPublicationStep
  [OnboardingStepTypes.BRANDING]: OnboardingProgressBrandingStep
  [OnboardingStepTypes.CONTACTS]: OnboardingProgressContactsStep
  [OnboardingStepTypes.EMAIL]: OnboardingProgressEmailStep
  [OnboardingStepTypes.EMAIL]: OnboardingProgressEmailStep
  [OnboardingStepTypes.PAYMENT]: OnboardingProgressPaymentStep
  [OnboardingStepTypes.POST]: OnboardingProgressFirstPostStep
}

export type OnboardingProgressFromSubscription = {
  isDismissed: boolean
  completedPercentage: string
  publicationStepSkipped: boolean
  publicationStepCompleted: boolean
  brandingStepSkipped: boolean
  brandingStepCompleted: boolean
  contactsStepSkipped: boolean
  contactsStepCompleted: boolean
  verifyEmailStepSkipped: boolean
  verifyEmailStepCompleted: boolean
  gettingPaidStepSkipped: boolean
  gettingPaidStepCompleted: boolean
  publishFirstPostStepCompleted: boolean
}

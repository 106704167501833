export const ERROR_FIELDS = `
  error {
    code
    message
    data
  }
`

export const META_FIELDS = `
  meta {
    cursor
    hasMoreItems
    totalCount
  }
`

import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { ERROR_FIELDS } from '../fragments'
import { handleQueryResponse } from '../../util'

export const MUTATION_HIDE_POST_INTERSTITIAL_PAGE = gql`
  mutation hidePostInterstitialPage($hide: Boolean) {
    hidePostInterstitialPage(hide: $hide) {
      data
      ${ERROR_FIELDS}
    }
  }
`
export const hidePostInterstitialPageMutation = async (hide: boolean) => {
  const res = await mutation(MUTATION_HIDE_POST_INTERSTITIAL_PAGE, { hide })

  return handleQueryResponse(res, 'hidePostInterstitialPage') as boolean
}

import { createSlice } from '@reduxjs/toolkit'

import { NotificationManager, NotificationLayout } from '@tellimer/ui/Notification'
import { Post, PostSort, PostFilterParams } from 'types/post.types'
import { fetchPosts } from './fetchPosts'
import { fetchPagePosts } from './fetchPagePosts'
import { fetchPublishedPosts } from './fetchPublishedPosts'
import { setSearch } from './actions/setSearch'
import { fetchPostsList, setPostListNextCursor, setCursor, setSort } from './actions'
import { setPostListPreviousCursor } from './actions/setPostListPreviousCursor'

export enum STATUS_POST {
  INIT = 'INIT_Post',
  FETCH_POSTS_STARTED = 'Fetching Posts',
  FETCH_POSTS_SUCCESS = 'Fetched Posts sucessfully',
  FETCH_POSTS_FAILED = 'Failed to fetch Posts',
  FETCH_POST_STARTED = 'Fetching Post',
  FETCH_POST_SUCCESS = 'Fetched Post sucessfully',
  FETCH_POST_FAILED = 'Failed to fetch Post',
}

export interface PostState {
  init: boolean
  status: STATUS_POST
  posts: Post[]
  latestPosts: Post[]

  fetching: boolean
  fetched: boolean
  loadingMore: boolean
  searching: boolean
  currentPage: number
  filterParams: PostFilterParams
  publishedPosts: Post[]
  hasPublishedPosts: boolean
  error: string | undefined

  // new values
  search?: string
  filter?: {
    title_contains?: string
    isPublished?: boolean
  }
  sort?: string
  querySort?: PostSort
  previousCursor: string[]
  cursor?: string
  nextCursor?: string
  totalCount: number
  isFetchingPosts?: boolean
  hasMoreRecords?: boolean
  startIndex: number
}

export const initialState: PostState = {
  init: false, // False until the first fetch
  status: STATUS_POST.INIT,
  posts: [],
  latestPosts: [],

  fetching: false,
  fetched: false,
  loadingMore: false,
  searching: false,
  currentPage: 0,
  filterParams: {},
  publishedPosts: [],
  hasPublishedPosts: false,
  error: '',

  search: '',
  sort: '',
  querySort: PostSort.NEWEST,
  previousCursor: [],
  cursor: '',
  nextCursor: '',
  totalCount: 0,
  isFetchingPosts: false,
  hasMoreRecords: false,
  startIndex: 0,
}

export const postSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setSearching(state, action) {
      state.searching = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPosts.pending, state => {
        state.fetching = !state.loadingMore && !state.searching
        state.fetched = false
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        const { data, meta, sort, filterParams, loadMore } = action.payload
        state.init = true
        state.totalCount = meta?.totalCount
        state.cursor = meta?.cursor
        state.sort = sort
        state.filterParams = filterParams
        if (loadMore) {
          state.posts = [...state.posts, ...data]
          state.latestPosts = [...state.posts, ...data]
        } else {
          state.currentPage = 0
          state.posts = [...data] // for test case
          state.latestPosts = [...data] // for dashboard case
        }
        state.fetching = false
        state.searching = false
        state.fetched = true
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.fetching = false
        state.searching = false
        state.fetched = true
        const errorMessage = action.error?.message || 'An error occurred while loading your posts'
        state.error = errorMessage
        NotificationManager.create({
          type: 'error',
          layout: NotificationLayout.SIMPLE,
          title: 'Error',
          text: errorMessage,
        })
      })
      .addCase(fetchPagePosts.pending, state => {
        state.loadingMore = true
      })
      .addCase(fetchPagePosts.fulfilled, (state, action) => {
        state.currentPage = action.payload
        state.loadingMore = false
      })
      .addCase(fetchPagePosts.rejected, state => {
        state.loadingMore = false
      })
      .addCase(fetchPublishedPosts.fulfilled, (state, action) => {
        state.publishedPosts = action.payload
        state.hasPublishedPosts = action.payload.length > 0
      })

      // Search updates
      .addCase(setSearch, (state, action) => {
        const { payload } = action
        state.search = payload.search
        state.filter = payload.filter
        state.cursor = ''
        state.nextCursor = ''
        state.previousCursor = []
      })

      // Sort
      .addCase(setSort, (state, action) => {
        const { payload } = action
        state.sort = payload.sort
        state.querySort = payload.querySort
        state.cursor = ''
        state.nextCursor = ''
        state.previousCursor = []
      })

      // Cursor
      .addCase(setCursor, (state, action) => {
        state.cursor = action.payload
      })

      // Fetch posts list
      .addCase(fetchPostsList.pending, state => {
        state.isFetchingPosts = true
      })

      .addCase(fetchPostsList.fulfilled, (state, action) => {
        const { payload } = action

        state.posts = payload.posts
        state.totalCount = payload.totalCount
        state.cursor = payload.cursor
        state.nextCursor = payload.nextCursor
        state.hasMoreRecords = payload.hasMoreRecords
        state.startIndex = payload.startIndex
        state.isFetchingPosts = false
      })

      .addCase(fetchPostsList.rejected, state => {
        state.isFetchingPosts = false
      })

      .addCase(setPostListNextCursor, state => {
        state.previousCursor = state.previousCursor.concat(state.cursor || '')
        state.cursor = state.nextCursor
      })

      .addCase(setPostListPreviousCursor, state => {
        const cursor = state.previousCursor[state.previousCursor.length - 1]

        state.previousCursor = state.previousCursor.slice(0, -1)
        state.nextCursor = state.cursor
        state.cursor = cursor
      })
  },
})

export * from './fetchPosts'
export * from './fetchPagePosts'
export const { setSearching } = postSlice.actions
export default postSlice.reducer

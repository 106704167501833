import { Auth } from 'aws-amplify'

const refreshUserSession = (user: any, refreshToken: string) =>
  new Promise((resolve, reject) => {
    user.refreshSession(refreshToken, (err: any, session: any) => {
      if (err) {
        return reject(err)
      }

      resolve(session)
    })
  })

export const getNewToken = async (): Promise<
  { token: string; refreshToken: string } | undefined
> => {
  // First we check the user login to get the auth token and store it on local state
  try {
    const user = await Auth.currentAuthenticatedUser()
    if (!user) {
      throw new Error("Can't get an user")
    }

    const { refreshToken } = user.getSignInUserSession()
    const session = (await refreshUserSession(user, refreshToken)) as any
    return {
      token: session.getIdToken().getJwtToken(),
      refreshToken: refreshToken.getToken(),
    }
  } catch (err) {
    //todo error handling should be different than token retrieval
    await Auth.signOut()
    window.location.assign(process.env.NEXT_PUBLIC_AMPLIFY_OAUTH_REDIRECT_SIGN_OUT || '/')
  }
}

export const getAuth = async (): Promise<{ token: string; refreshToken: string } | undefined> => {
  const user = await Auth.currentAuthenticatedUser()
  if (!user) {
    throw new Error("Can't get an user")
  }

  const session = user.getSignInUserSession()

  return {
    refreshToken: session.getRefreshToken().getToken(),
    token: session.getIdToken().getJwtToken(),
  }
}

//todo this is not currently used as the authExchange hook does not allow for a Promise<boolean> type
export const willAuthError = async () => {
  const user = await Auth.currentAuthenticatedUser()
  const session = user.getSignInUserSession()

  return session.isValid()
}

export const logout = async () => {
  await Auth.signOut()
  window.location.assign(process.env.NEXT_PUBLIC_AMPLIFY_OAUTH_REDIRECT_SIGN_OUT || '/')
}

import { createAsyncThunk } from '@reduxjs/toolkit'

import { setupStripeConnectMutation } from 'gql/payments/mutations'
import { selectPublication } from 'redux/publication/selector'

export const setupStripeConnect = createAsyncThunk(
  'payments/setupStripeConnect',
  async (authorizationCode: string, { getState }) => {
    const state = getState() as any
    const publication = selectPublication(state)
    await setupStripeConnectMutation(publication.id, authorizationCode)
  },
)

import { createAsyncThunk } from '@reduxjs/toolkit'
import { listOnboardingProgressQuery } from 'gql/dashboard'
import { query } from 'gql'

import { selectCreator } from '../auth/selector'

export const fetchOnboardingProgress = createAsyncThunk(
  'onboardingProgress/fetchOnboardingProgress',
  async (_, { getState }) => {
    const state = getState() as any
    const creator = selectCreator(state)

    if (creator?.hasCompletedOnboarding) {
      return
    }

    const { data, error } = await query(listOnboardingProgressQuery, {
      publicationId: creator.publication.id,
    })

    if (error) {
      throw new Error(error.message)
    }

    if (data?.listOnboardingProgress?.error) {
      throw new Error(data.listOnboardingProgress?.error)
    }

    return { ...data?.listOnboardingProgress?.data }
  },
)

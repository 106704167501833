import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { handleMutationResponse } from 'gql/util'
import { ERROR_FIELDS } from '../fragments'

export const MUTATION_SET_WELCOME_EMAIL = gql`
  mutation setWelcomeEmail($data: SetWelcomeEmailInput!) {
    setWelcomeEmail(data: $data) {
        data
        ${ERROR_FIELDS}
    }
  }
`

export const setWelcomeEmailMutation = async (id: string, data: any) => {
  if (!id) {
    throw new Error("Can't update a publication without id")
  }
  if (!data) {
    throw new Error("Can't updated a publication without data")
  }

  const res = await mutation(MUTATION_SET_WELCOME_EMAIL, { data: { publicationId: id, ...data } })
  await handleMutationResponse(res, 'setWelcomeEmail')
}

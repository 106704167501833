import { createAsyncThunk } from '@reduxjs/toolkit'
import { NotificationLayout, NotificationManager } from '@tellimer/ui'
import { deletePostMutation } from 'gql/post/mutations'

export const deletePost = createAsyncThunk('postDetail/deletePost', async (postId: string) => {
  try {
    await deletePostMutation(postId)
  } catch (e) {
    console.error(e)
    NotificationManager.create({
      type: 'error',
      layout: NotificationLayout.SIMPLE,
      title: 'Error',
      text: 'Something failed while retrieving the post',
    })
    throw e
  }
})

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import * as Glyph from '@tellimer/ui/Icon'
import { NotificationManager, NotificationLayout } from '@tellimer/ui/Notification'

import SearchResultsList from './SearchResultsList'
import { fetchSearchResults, resetSearchResults } from 'redux/search/slice'
import { useAppSelector } from 'hooks/reduxHooks'
import { selectSearchResults } from 'redux/search/selector'
import useDebounce from 'hooks/useDebounce'

const SearchInput: React.FC = () => {
  const [isEditingSearchKey, setIsEditingSearchKey] = useState(false)
  const dispatch = useDispatch()
  const [searchKey, setSearchKey] = useState('')
  const debouncedSearchKey = useDebounce(searchKey, 200)
  const searchResults = useAppSelector(selectSearchResults)

  const classes = {
    container: classNames('flex flex-1 items-center px-2 lg:p-2 lg:rounded-md', {
      'lg:bg-white lg:ring-2 lg:ring-accent-500 lg:ring-offset-0': isEditingSearchKey,
      'lg:cursor-pointer lg:hover:bg-gray-800 lg:transition': !isEditingSearchKey,
    }),
    label: classNames('text-gray-300 lg:text-sm', {
      'lg:font-medium': !isEditingSearchKey,
    }),
    input: classNames(
      'w-full p-0 lg:text-sm text-gray-300 placeholder-gray-300 border-0 focus:outline-none focus:ring-0 focus:border-transparent bg-transparent',
      {
        'lg:text-gray-600': isEditingSearchKey,
      },
    ),
    searchIcon: classNames('flex-shrink-0 w-5 lg:w-6 h-5 lg:h-6 mr-2 lg:mr-3 fill-gray-400', {
      'lg:fill-gray-500': isEditingSearchKey,
    }),
    closeIcon: classNames('self-center w-3 h-3 fill-gray-400 mr-1'),
  }

  function resetSearch() {
    setSearchKey('')
    setIsEditingSearchKey(false)
    dispatch(resetSearchResults())
  }

  useEffect(() => {
    if (!searchKey.trim()) return

    function fetchSearch() {
      try {
        return dispatch(fetchSearchResults({ query: debouncedSearchKey }))
      } catch (error) {
        console.error(error)
        NotificationManager.create({
          type: 'error',
          layout: NotificationLayout.CONDENSED,
          title: 'An error occurred while fetching results',
        })
      }
    }

    fetchSearch()
  }, [debouncedSearchKey])

  return (
    <div className="w-full relative">
      {!isEditingSearchKey ? (
        <div className={classes.container} onClick={() => setIsEditingSearchKey(true)}>
          <Glyph.Search className={classes.searchIcon} title="Search" />
          <span className={classes.label}>Search</span>
        </div>
      ) : (
        <div className={classes.container}>
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <Glyph.Search className={classes.searchIcon} title="Search" />
          <input
            id="search-field"
            className={classes.input}
            autoFocus
            autoComplete="off"
            placeholder="Search"
            type="search"
            name="search"
            value={searchKey}
            onBlur={resetSearch}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchKey(e.target.value)}
          />
          {searchKey ? (
            <span className="w-5 h-5 flex content-center justify-center cursor-pointer">
              <Glyph.Close
                className={classes.closeIcon}
                onClick={resetSearch}
                title="Clear search"
              />
            </span>
          ) : null}
        </div>
      )}

      <SearchResultsList searchResults={searchResults} searchKey={searchKey} />
    </div>
  )
}

export default SearchInput

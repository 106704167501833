import { createAsyncThunk } from '@reduxjs/toolkit'
import { exportSubscribersMutation } from 'gql/subscriber/mutations/exportSubscribers'
import { selectPublication } from 'redux/publication/selector'
import { selectExportingSubscriptionInitiated, selectFilterParams, selectSort } from '../selector'
import { initExportSubscribersSubscription } from './initExportSubscribersSubscription'

export const exportSubscribers = createAsyncThunk(
  'subscribers/exportSubscribers',
  async (_, { getState, dispatch }) => {
    const state = getState() as any
    const publication = selectPublication(state)
    const exportingSubscriptionInitiated = selectExportingSubscriptionInitiated(state)
    const sort = selectSort(state)
    const filterParams = selectFilterParams(state)

    const where = filterParams || {}

    await exportSubscribersMutation({ publicationId: publication.id, where, sort })

    if (!exportingSubscriptionInitiated) {
      await dispatch(initExportSubscribersSubscription()).unwrap()
    }

    return {
      exportSubscribers: {
        exporting: true,
      },
    }
  },
)

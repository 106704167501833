import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationLayout, NotificationManager } from '@tellimer/ui/Notification'
import { getPublicationQuery } from 'gql/publication/queries'
import { updatePublicationMutation } from 'gql/publication/mutations'
import { getCreator } from 'redux/auth/selector'
import { Publication } from 'types/publication.types'
import omitDeep from 'helper/omitDeep'
import { updatePublication, sendTestWelcomeEmail } from './actions'
import { saveSubscriptionPackages } from 'redux/payments/actions'

export enum STATUS_UPDATING_PUBLICATION {
  UPDATING = 'updating_publication',
  UPDATED = 'updated_publication',
  FAILED = 'update_failed',
}

export enum STATUS_FETCHING_PUBLICATION {
  FETCHING = 'fetching_publication',
  FETCHED = 'fetched_publication',
  FAILED = 'fetch_failed',
}

export interface PublicationState {
  publication: Publication
  status?: STATUS_UPDATING_PUBLICATION | STATUS_FETCHING_PUBLICATION
  error: string | undefined
  isEmailing: boolean
}

export const initialState: PublicationState = {
  publication: { id: '' },
  status: undefined,
  error: '',
  isEmailing: false,
}

export const fetchPublication = createAsyncThunk(
  'publication/fetchPublication',
  async (_, { getState }) => {
    const state = getState() as any
    const creator = getCreator(state)
    return await getPublicationQuery(creator?.publication?.id)
  },
)

export const storePublicationAsync = createAsyncThunk(
  'auth/storePublication',
  async (
    {
      id,
      fields,
      successMessage,
    }: { id: string; fields: Partial<Publication>; successMessage?: string },
    { rejectWithValue },
  ) => {
    try {
      const data = await updatePublicationMutation(id, omitDeep(fields))
      return {
        data,
        message: successMessage,
      }
    } catch (e: any) {
      return rejectWithValue(e?.message)
    }
  },
)

export const publicationSlice = createSlice({
  name: 'publication',
  initialState,
  reducers: {
    setPublication: (state, action: PayloadAction<Partial<Publication>>) => {
      state.publication = { ...state.publication, ...action.payload } as Publication
    },
  },
  extraReducers: builder => {
    builder
      // Fetch publication
      .addCase(fetchPublication.pending, state => {
        state.status = STATUS_FETCHING_PUBLICATION.FETCHING
        state.error = ''
      })
      .addCase(fetchPublication.fulfilled, (state, action) => {
        state.status = STATUS_FETCHING_PUBLICATION.FETCHED
        state.error = ''
        state.publication = action.payload
      })
      .addCase(fetchPublication.rejected, (state, action) => {
        state.status = STATUS_FETCHING_PUBLICATION.FAILED
        state.error = JSON.stringify(action.error)
      })

      // Store publication async
      // FIXME: Delete this and replace it with updatePublication
      .addCase(storePublicationAsync.fulfilled, (state, action) => {
        state.status = STATUS_UPDATING_PUBLICATION.UPDATED
        const { data, message } = action.payload as any
        NotificationManager.create({
          type: 'success',
          layout: NotificationLayout.SIMPLE,
          title: 'Success',
          text: message || 'Saved successfully',
        })
        state.error = ''
        state.publication = omitDeep({ ...state.publication, ...data })
      })
      .addCase(storePublicationAsync.pending, state => {
        state.status = STATUS_UPDATING_PUBLICATION.UPDATING
        state.error = ''
      })
      .addCase(storePublicationAsync.rejected, (state, action) => {
        const { message } = action.payload as any
        NotificationManager.create({
          type: 'error',
          layout: NotificationLayout.SIMPLE,
          title: 'Error',
          text: message || 'Failed!',
        })
        state.error = message
        state.status = STATUS_UPDATING_PUBLICATION.FAILED
      })

      // Update publication
      .addCase(updatePublication.pending, state => {
        state.status = STATUS_UPDATING_PUBLICATION.UPDATING
        state.error = ''
      })
      .addCase(updatePublication.fulfilled, (state, action) => {
        state.status = STATUS_UPDATING_PUBLICATION.UPDATED
        state.error = ''
        state.publication = action.payload
      })

      .addCase(updatePublication.rejected, (state, action) => {
        state.error = action.payload as any
        state.status = STATUS_UPDATING_PUBLICATION.FAILED
      })

      // Send test welcome email
      .addCase(sendTestWelcomeEmail.pending, state => {
        state.isEmailing = true
      })
      .addCase(sendTestWelcomeEmail.rejected, (state, action) => {
        state.isEmailing = false
        state.error = action.payload as any
      })
      .addCase(sendTestWelcomeEmail.fulfilled, state => {
        state.isEmailing = false
      })

      // Subscription package saved. WHen this is doen we need to update the publication
      // for the hasPaidPackage flag
      .addCase(saveSubscriptionPackages.fulfilled, (state, action) => {
        state.publication = action.payload.publication
      })
  },
})

export const { setPublication } = publicationSlice.actions
export default publicationSlice.reducer

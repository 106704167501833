import { createSlice } from '@reduxjs/toolkit'
import { Subscriber } from 'types/subscriber.types'
import {
  setSubscriberOptedOut,
  fetchSubscriberDetails,
  deleteSubscriber,
  downgradeSubscriber,
  updateSubscriber,
} from './actions'
export interface SubscriberDetailState {
  isLoading: boolean
  isOptingOut: boolean
  isDeleting: boolean
  isDowngrading: boolean
  isUpdating: boolean
  subscriber?: Subscriber
}

export const initialState: SubscriberDetailState = {
  isLoading: false,
  isOptingOut: false,
  isDeleting: false,
  isDowngrading: false,
  isUpdating: false,
}

export const subscriberDetailSlice = createSlice({
  name: 'subscriberDetail',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // Fetch subscriber
      .addCase(fetchSubscriberDetails.pending, state => {
        state.isLoading = true
      })
      .addCase(fetchSubscriberDetails.fulfilled, (state, action) => {
        state.isLoading = false
        state.subscriber = action.payload
      })
      .addCase(fetchSubscriberDetails.rejected, state => {
        state.isLoading = false
        state.subscriber = undefined
      })

      // OptOut of commns
      .addCase(setSubscriberOptedOut.pending, state => {
        state.isOptingOut = true
      })

      .addCase(setSubscriberOptedOut.fulfilled, (state, action) => {
        state.isOptingOut = false
        state.subscriber = action.payload
      })

      .addCase(setSubscriberOptedOut.rejected, state => {
        state.isOptingOut = false
      })

      // Delete subscriber
      .addCase(deleteSubscriber.pending, state => {
        state.isDeleting = true
      })
      .addCase(deleteSubscriber.fulfilled, state => {
        state.isDeleting = false
      })
      .addCase(deleteSubscriber.rejected, state => {
        state.isDeleting = false
      })

      // Downgrade subscriber
      .addCase(downgradeSubscriber.pending, state => {
        state.isDowngrading = true
      })
      .addCase(downgradeSubscriber.fulfilled, (state, action) => {
        state.isDowngrading = false
        state.subscriber = action.payload
      })
      .addCase(downgradeSubscriber.rejected, state => {
        state.isDowngrading = false
      })

      // UIpdate subscriber
      .addCase(updateSubscriber.pending, state => {
        state.isUpdating = true
      })
      .addCase(updateSubscriber.fulfilled, (state, action) => {
        state.isUpdating = false
        state.subscriber = action.payload
      })
      .addCase(updateSubscriber.rejected, state => {
        state.isUpdating = false
      })
  },
})

export default subscriberDetailSlice.reducer

import { Creator } from 'types/creator.types'
import type { AppState } from '../store'

export const getCreator = (state: AppState) => state.auth.creator
export const getAuthError = (state: AppState) => state.auth.error
export const getAuthStatus = (state: AppState) => state.auth.status

export const selectCreator = (state: AppState) => state.auth.creator as Creator
export const selectIsUpdatingCreator = (state: AppState) => state.auth.isUpdating
export const selectCreatorHasVerifiedEmail = (state: AppState) =>
  state.auth.creator.hasVerifiedEmail
export const selectCreatorHasCompletedOnboarding = (state: AppState) =>
  state.auth.creator.hasCompletedOnboarding

export const selectCreatorShowPostAccessHighlight = (state: AppState) =>
  state.auth.creator.showPostAccessHighlight

import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { handleMutationResponse } from 'gql/util'
import { ERROR_FIELDS } from '../common'

export const DOWNGRADE_TO_FREE = gql`
  mutation DowngradeToFree($publicationId: ID!, $subscriberId: ID!) {
    downgradeToFree(publicationId: $publicationId, subscriberId:$subscriberId) {
      data
      ${ERROR_FIELDS}
    }
  }
`

export const downgradeToFreeMutation = async (variables: {
  publicationId: string
  subscriberId: string
}) => {
  if (!variables) throw new Error("Can't downgrade without variables")
  if (!variables.publicationId) throw new Error("Can't downgrade without publication id")
  if (!variables.subscriberId) throw new Error("Can't downgrade without subscriber id")

  const response = await mutation(DOWNGRADE_TO_FREE, variables)
  return handleMutationResponse(response, 'downgradeToFree') as boolean
}

import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { handleMutationResponse } from 'gql/util'
import { SubscriptionUpdated } from 'types/subscriber.types'
import { ERROR_FIELDS } from '../common'

export const MUTATION_ADD_SUBSCRIBER = gql`
  mutation addSubscriber($publicationId: ID!, $email: String!, $fullName: String) {
    addSubscriber(
      data: {
        publicationId: $publicationId,
        email: $email,
        fullName: $fullName
      }) {
      data {
        isConfirmed
        confirmedAt
      }
      ${ERROR_FIELDS}
    }
  }
`

export type AddSubscriberVariablesType = {
  publicationId: string
  email: string
  fullName?: string
}

export const addSubscriberMutation = async (variables: AddSubscriberVariablesType) => {
  if (!variables) throw new Error("Can't add a subscriber without variables")
  if (!variables.publicationId) throw new Error("Can't add a subscriber without publication id")
  if (!variables.email) throw new Error("Can't add a subscriber without email")

  const response = await mutation(MUTATION_ADD_SUBSCRIBER, variables)
  return handleMutationResponse(response, 'addSubscriber') as SubscriptionUpdated
}

import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'

import { handleMutationResponse } from '../../util'
import { ERROR_FIELDS } from '../fragments'

export const MUTATION_SETUP_STRIPE_CONNECT = gql`
  mutation SetupStripeConnect($publicationId: String!, $authorizationCode: String!) {
    setupStripeConnect(publicationId: $publicationId, authorizationCode: $authorizationCode) {
      data
      ${ERROR_FIELDS}
    }
  }
`

export const setupStripeConnectMutation = async (
  publicationId: string,
  authorizationCode: string,
) => {
  if (!publicationId || !authorizationCode) {
    throw new Error("Can't set up Stripe Connect without publication id or authorization code")
  }

  const res = await mutation(MUTATION_SETUP_STRIPE_CONNECT, { publicationId, authorizationCode })

  return handleMutationResponse(res, 'setupStripeConnect')
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { NotificationLayout, NotificationManager } from '@tellimer/ui'
import { unpublishPostMutation } from 'gql/post/mutations'

export const unpublishPost = createAsyncThunk('postDetail/unpublishPost', async (id: string) => {
  try {
    await unpublishPostMutation(id)
  } catch (e) {
    console.error(e)
    NotificationManager.create({
      type: 'error',
      layout: NotificationLayout.SIMPLE,
      title: 'Error',
      text: 'Something failed while publishing the post',
    })
    throw e
  }
})

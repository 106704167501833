import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { ScriberFileType, uploadFile } from '@tellimer/file-upload'
import { NotificationLayout, NotificationManager } from '@tellimer/ui'

import { getClient, GQL_CLIENT_TYPES } from 'gql'
import { uploadContacts } from 'gql/publication/mutations'

import { selectPublication } from '../publication/selector'
import { updateImportStatus, ImportStatus } from './updateImportStatus'
import { selectIsImportInProgress } from './selector'

export type ImportDetails = {
  fileDetails: any
  status: ImportStatus
  totalContactsToImport: number
  totalSkippedContacts: number
  totalImportedContacts: number
}

/**
 * This method deals only with the upload of the files, once it is on a bucket
 * then we can use its name to trigger the import
 * @param file Actual file object form the browser
 * @param publicationId Current Publication's id for better association and management of files
 * @returns The file details from `@tellimer/file-upload`, at least `src` and `name`
 */
export const uploadCSV = async (file: File, publicationId: string) => {
  const client = getClient(GQL_CLIENT_TYPES.PRIVATE)
  return await uploadFile(file, client, { fileType: ScriberFileType.CONTACTS, publicationId })
}

export const setIsImportInProgress = createAction<boolean>(
  'importSubscribers/setIsImportInProgress',
)
export const setImportFileDetails = createAction<any>('importSubscribers/setImportFileDetails')

/**
 * This action will start the upload process for new subscribers. It requires a file object for a CSV file and it
 * will upload and listen to any updates on the processing done on the backend.
 * This will only process the file to get the list of subscribers from it and what are the options users have
 * (imported vs skipped mainly), then we need to call the `importPendingSubscribers` one to actually do the import
 */
export const uploadSubscribers = createAsyncThunk(
  'importSubscribers/uploadSubscribers',
  async (file: File, thunkAPI) => {
    const { getState, dispatch } = thunkAPI
    const state = getState() as any

    // FIXME: We need to make sure that we don't do this twice, we may want to fetch the state also
    // from publication in case the local and remote state don't match
    const isImportingInProgress = selectIsImportInProgress(state)
    if (isImportingInProgress) {
      return
    }

    dispatch(setIsImportInProgress(true))
    dispatch(updateImportStatus(ImportStatus.PROCESSING))

    try {
      const publication = selectPublication(state)

      // First we upload the CSV
      const fileDetails = await uploadCSV(file, publication.id)
      setImportFileDetails(fileDetails)

      // Then we actually get the file and we start the process to extract the subscriber information
      await uploadContacts(fileDetails.key, publication.id)
    } catch (err: any) {
      NotificationManager.create({
        type: 'error',
        layout: NotificationLayout.CONDENSED,
        title: 'An error occurred while importing your contacts',
      })

      dispatch(updateImportStatus(ImportStatus.ERROR_PROCESSING))
    }
  },
)

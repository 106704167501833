/**
 * THis method will delete a querystring with the given key from the url via replaceState
 * @param key {string}
 */
export const deleteKeyFromLocationSearch = (key: string) => {
  const currentSearch = getCurrentSerachParams()

  // If we don't have search we are on the backend so recan safely return
  if (!currentSearch) return

  // Sadly delete on URLSearchParams is only iOS 14+ so we do it the long way
  const newSearch = Object.fromEntries(Array.from(currentSearch))
  delete newSearch[key]

  const newURL = new URL(window.location.href)
  newURL.search = new URLSearchParams(newSearch).toString()

  window.history.replaceState(null, '', newURL)
}

export const getCurrentSerachParams = () => {
  if (typeof window === 'undefined') return null
  return new URLSearchParams(window.location.search)
}

/**
 * THis method will delete a querystring with the given key from the url via replaceState
 * @param key {string}
 */
export const updateLocationSearch = (
  key: string,
  value: string,
  options = { overwrite: true, skipHistory: false, keepEmpty: false },
) => {
  const search = getCurrentSerachParams()

  // If we don't have search we are on the backend so recan safely return
  if (!search) return

  if (!value && !options?.keepEmpty) {
    search.delete(key)
  } else if (options?.overwrite) {
    search.set(key, value)
  } else {
    search.append(key, value)
  }

  const newURL = new URL(window.location.href)
  newURL.search = new URLSearchParams(search).toString()

  if (options?.skipHistory) {
    window.history.replaceState(null, '', newURL)
  } else {
    window.history.pushState(null, '', newURL)
  }
}

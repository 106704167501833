import { gql } from '@tellimer/graphql-client'
import { ERROR_FIELDS } from './fragments'

export const sendVerificationEmail = gql`
  mutation {
    sendVerificationEmail {
      data
      ${ERROR_FIELDS}
    }
  }
`

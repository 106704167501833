import { createAsyncThunk } from '@reduxjs/toolkit'
import { UpdateableCreatorFields } from 'types/creator.types'
import { updateCreatorMutation } from 'gql/creator/mutations'
import { NotificationLayout, NotificationManager } from '@tellimer/ui'

export const updateCreator = createAsyncThunk(
  'auth/updateCreator',
  async (fields: UpdateableCreatorFields) => {
    try {
      return await updateCreatorMutation(fields)
    } catch (e) {
      console.error(e)
      NotificationManager.create({
        type: 'error',
        layout: NotificationLayout.SIMPLE,
        title: 'Error',
        text: 'Something failed while saving the creator',
      })
      throw e
    }
  },
)

import { gql } from '@tellimer/graphql-client'
import { ERROR_FIELDS } from './fragments'

export const listOnboardingsQuery = gql`
  query ListOnboardings {
    listOnboardings {
      data {
        publicationStep {
          weight
          timeToFillOut
          publicationField {
            isNameRequired
            isDescriptionRequired
            isDomainRequired
          }
        }
        brandingStep {
          weight
          timeToFillOut
          customisationField {
            isAccentColorRequired
            isLogoRequired
          }
        }
        contactsStep {
          weight
          timeToFillOut
          subscribersField {
            isNameRequired
            isEmailRequired
          }
        }
        verifyEmailStep {
          weight
          timeToFillOut
        }
        publishFirstPostStep {
          weight
          timeToFillOut
        }
        gettingPaidStep {
          weight
          timeToFillOut
        }
      }
      ${ERROR_FIELDS}
    }
  }
`

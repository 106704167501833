import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { handleQueryResponse } from '../../util'
import { ERROR_FIELDS } from '../fragments'

export const MUTATION_OPT_OUT = gql`
  mutation PublishPost($subscriberId: ID!, $publicationId: ID!, $optOut: Boolean!) {
    optOut(data: { subscriberId: $subscriberId, publicationId: $publicationId, optOut: $optOut }) {
      data
      ${ERROR_FIELDS}
    }
  }
`

export const optOutMutation = async (
  subscriberId: string,
  publicationId: string,
  optOut: boolean,
) => {
  const res = await mutation(MUTATION_OPT_OUT, {
    subscriberId,
    publicationId,
    optOut,
  })
  return handleQueryResponse(res, 'optOut') as boolean
}

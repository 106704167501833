import { gql } from '@tellimer/graphql-client'
import { ERROR_FIELDS } from './fragments'

export const analyticsQuery = gql`
  query Analytics($publicationId: ID!, $timeInterval: AnalyticsTimeInterval) {
    analytics(publicationId: $publicationId, timeInterval: $timeInterval) {
      data {
        keyMetrics {
          name
          currentValue
          description
          chartData
          showChart
          lastChangedAt
          showLastChangedAt
          change {
            value
            direction
          }
        }
        chartMetric {
          name
          currentValue
          description
          chartData
          showChart
          change {
            value
            direction
          }
        }
      }
      ${ERROR_FIELDS}
    }
  }
`

export const availableIntervalsQuery = gql`
  query ListAvailableIntervals {
    listAvailableIntervals {
      data {
        name
        value
      }
      ${ERROR_FIELDS}
    }
  }
`

export const AMPLIFY_CONFIG = {
  Auth: {
    region: process.env.NEXT_PUBLIC_AMPLIFY_REGION,
    userPoolId: process.env.NEXT_PUBLIC_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_AMPLIFY_APP_CLIENT_ID,
    cookieStorage: {
      domain: process.env.AMPLIFY_BASE_DOMAIN,
      secure: process.env.AMPLIFY_IS_SECURE_PROTOCOL === 'false' ? false : true,
      path: '/',
      expires: 365,
    },
  },
  oauth: {
    domain: process.env.NEXT_PUBLIC_AMPLIFY_OAUTH_DOMAIN,
    redirectSignIn: process.env.NEXT_PUBLIC_AMPLIFY_OAUTH_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.NEXT_PUBLIC_AMPLIFY_OAUTH_REDIRECT_SIGN_OUT,
    responseType: 'code',
    scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  },
  ssr: true,
}

import { updateUser } from './scriberAnalyticsApi'

export const trackPostPublication = async (publishTo: string) => {
  await updateUser({
    traits: {
      scriber_published_post: true,
    },
    type: 'post-publish',
    event: {
      publishTo,
    },
  })
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Creator } from 'types/creator.types'
import { fetchCreator } from './actions/fetchCreator'
import { updateCreator } from './actions/updateCreator'
import { deleteAccount } from './actions/deleteAccount'
import { updateHidePostInterstitial } from 'redux/postDetail/actions'
import { saveSubscriptionPackages } from 'redux/payments/actions'

export enum STATUS_CREATOR {
  INIT = 'INIT_Creator',
  FETCH_CREATOR_STARTED = 'Fetching Creator',
  FETCH_CREATOR_SUCCESS = 'Fetched Creator successfully',
  FETCH_CREATOR_FAILED = 'Failed to fetch Creator',
  UPDATE_CREATOR_STARTED = 'Updating Creator',
  UPDATE_CREATOR_SUCCESS = 'Updated Creator successfully',
  UPDATE_CREATOR_FAILED = 'Failed to update Creator',
  DELETE_ACCOUNT_STARTED = 'Deleting Account',
  DELETE_ACCOUNT_SUCCESS = 'Deleting Account successfully',
  DELETE_ACCOUNT_FAILED = 'Failed to delete Account',
}

export interface AuthState {
  creator: Partial<Creator>
  status?: STATUS_CREATOR
  error: string
  isUpdating: boolean
  showPostAccessHighlight: boolean
}

export const initialState: AuthState = {
  creator: {},
  status: undefined,
  error: '',
  isUpdating: false,
  showPostAccessHighlight: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCreator: (state, action: PayloadAction<Creator>) => {
      state.creator = action.payload
    },
  },
  extraReducers: builder => {
    builder
      // Fetch creator
      .addCase(fetchCreator.pending, state => {
        state.status = STATUS_CREATOR.FETCH_CREATOR_STARTED
        state.error = ''
      })
      .addCase(fetchCreator.fulfilled, (state, action) => {
        state.status = STATUS_CREATOR.FETCH_CREATOR_SUCCESS
        state.error = ''
        state.creator = action.payload
      })
      .addCase(fetchCreator.rejected, (state, action) => {
        state.error = JSON.stringify(action.error)
        state.status = STATUS_CREATOR.FETCH_CREATOR_FAILED
      })

      // Update creator
      .addCase(updateCreator.pending, state => {
        state.status = STATUS_CREATOR.UPDATE_CREATOR_STARTED
        state.isUpdating = true
        state.error = ''
      })
      .addCase(updateCreator.fulfilled, (state, action) => {
        state.status = STATUS_CREATOR.UPDATE_CREATOR_SUCCESS
        state.isUpdating = false
        state.error = ''
        state.creator = { ...state.creator, ...action.payload }
      })
      .addCase(updateCreator.rejected, (state, action) => {
        state.error = JSON.stringify(action.error)
        state.status = STATUS_CREATOR.UPDATE_CREATOR_FAILED
        state.isUpdating = false
      })

      // Delete account
      .addCase(deleteAccount.fulfilled, state => {
        state.status = STATUS_CREATOR.DELETE_ACCOUNT_SUCCESS
        state.error = ''
        state.creator = {}
      })
      .addCase(deleteAccount.pending, state => {
        state.status = STATUS_CREATOR.DELETE_ACCOUNT_STARTED
        state.error = ''
      })
      .addCase(deleteAccount.rejected, (state, action) => {
        state.error = JSON.stringify(action.error)
        state.status = STATUS_CREATOR.DELETE_ACCOUNT_FAILED
      })

      // Toggle post interstitial, this can be done from posts or settings
      .addCase(updateHidePostInterstitial.fulfilled, (state, action) => {
        state.creator = action.payload
      })

      // Subscription package saved. WHen this is doen we need to update the publication
      // for the hasPaidPackage flag
      .addCase(saveSubscriptionPackages.fulfilled, (state, action) => {
        state.creator = action.payload.creator
      })
  },
})

export const { setCreator } = authSlice.actions
export default authSlice.reducer

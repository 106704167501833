import { createAsyncThunk } from '@reduxjs/toolkit'
import { listOnboardingsQuery } from 'gql/dashboard'
import { query } from 'gql'
import { getCreator } from '../auth/selector'

export const fetchOnboarding = createAsyncThunk(
  'onboarding/fetchOnboarding',
  async (_, { getState }) => {
    const state = getState() as any
    const creator = getCreator(state)
    if (creator?.hasCompletedOnboarding) {
      return
    }

    const { data, error } = await query(listOnboardingsQuery)

    if (data?.listOnboardings?.error || error) {
      throw new Error(data?.listOnboardings?.error?.message || error?.message)
    }

    return data?.listOnboardings.data
  },
)

import { OperationResult } from '@urql/core'
import { getLogger } from 'helper/logger'

export const handleGQLError = (error: any, key: string) => {
  if (!error) return

  const logger = getLogger(`GQL error @ ${key}`)

  if (error.message && error.message.includes('Token has expired')) {
    logger.info(`Token expired, redirecting to login`)
    window.location.assign(process.env.NEXT_PUBLIC_AMPLIFY_OAUTH_REDIRECT_SIGN_OUT || '/')
    return
  }

  if (error.message || error.code) {
    logger.error(error.code, '-', error.message, '-', error.stack)
    throw error
  }

  logger.error(error)
  throw new Error(error)
}

export const handleQueryResponse = (res: OperationResult, key: string) => {
  handleGQLError(res.error, key)

  if (!res.data) {
    throw new Error(`Missing query data on "${key}"`)
  }

  const { error, data } = res.data[key] || {}
  handleGQLError(error, key)

  return data
}

export const handleMutationResponse = (res: OperationResult, key: string) => {
  handleGQLError(res.error, key)

  if (!res.data) {
    throw new Error(`Missing mutation data on "${key}"`)
  }

  const { error, data } = res.data[key] || {}
  handleGQLError(error, key)

  return data
}

export const handleQueryResponseWithMeta = (res: OperationResult, key: string) => {
  handleGQLError(res.error, key)

  if (!res.data) {
    throw new Error(`Missing query data on "${key}"`)
  }

  const { error, data, meta } = res.data[key] || {}
  handleGQLError(error, key)

  return { data, meta }
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCreatorQuery } from 'gql/creator/queries'
import {
  saveSubscriptionPackageMutation,
  SaveSubscriptionPackageInput,
} from 'gql/payments/mutations'
import { listSubscriptionTypesQuery } from 'gql/payments/queries'

import { selectPublication } from 'redux/publication/selector'
import { SubscriptionPackageDetails } from 'types/payment.types'

export const saveSubscriptionPackages = createAsyncThunk(
  'payments/saveSubscriptionPackages',
  async (packages: SubscriptionPackageDetails[], { getState }) => {
    const state = getState() as any
    const publication = selectPublication(state)

    if (!publication) throw new Error("Can't save a package without a publication")

    await Promise.all(
      packages.map(details => {
        const { type, isEnabled, monthlyPrice, annualDiscount, description } = details

        const packageDetails: SaveSubscriptionPackageInput = {
          type: type,
          publicationId: publication.id,
          isEnabled,
          price: monthlyPrice,
          annualDiscount,
          description,
        }

        return saveSubscriptionPackageMutation(packageDetails)
      }),
    )

    // Since the save doesn't return anything we refetch to make sure we got the latest data
    const [subscriptionTypes, creator] = await Promise.all([
      listSubscriptionTypesQuery(publication.id),
      getCreatorQuery(),
    ])

    return {
      subscriptionTypes,
      creator,
      publication: creator.publication,
    }
  },
)

import React, { Fragment } from 'react'

import { Popover, Transition } from '@tellimer/ui'
import { useMediaQuery } from '@tellimer/hooks'

import NavItems from './NavItems'
import Footer from './Footer'
import Header from './Header'

const Sidebar: React.FC = () => {
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  const panelClasses =
    'fixed w-full lg:h-screen ' +
    'bg-gray-700 ' +
    'shadow-lg ring-1 ring-black ring-opacity-5 rounded-b-lg lg:rounded-none ' +
    'overflow-hidden lg:overflow-visible'

  return (
    <Popover className="shrink-0 w-full lg:w-64 bg-gray-700">
      <Header />
      <Transition
        as={Fragment}
        show={isDesktop || undefined}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 lg:opacity-100 translate-y-1 lg:translate-y-0"
        enterTo="opacity-100 translate-y-0"
        leave="transition lg:transition-none ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 lg:opacity-100 translate-y-1 lg:translate-y-0"
      >
        <div className="px-2 lg:px-0 w-full lg:w-64 absolute lg:sticky z-10 top-2 lg:top-0 left-1/2 md:left-0 transform -translate-x-1/2 md:translate-x-0">
          <Popover.Panel className={panelClasses}>
            {({ close }: any) => (
              <>
                <NavItems closePopover={close} />
                <Footer />
              </>
            )}
          </Popover.Panel>
        </div>
      </Transition>
    </Popover>
  )
}

export default Sidebar

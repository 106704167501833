import { createAsyncThunk } from '@reduxjs/toolkit'

import { selectCreator } from 'redux/auth/selector'
import { listPostsQuery } from 'gql/post/queries'
import { PostSort } from 'types/post.types'

export const fetchPublishedPosts = createAsyncThunk(
  'posts/fetchPublishedPosts',
  async (_, { getState }) => {
    const state = getState() as any
    const creator = selectCreator(state)
    try {
      const { data } = await listPostsQuery({
        sort: [PostSort.LATEST_PUBLISHED],
        limit: 5,
        after: '',
        where: {
          isPublished: true,
          publication: creator.publication?.id,
        },
      })
      return data || []
    } catch (e) {
      throw e
    }
  },
)

import { gql } from '@tellimer/graphql-client'

export const importProgressUpdatedSubscription = gql`
  subscription importProgressUpdated($publicationId: ID!) {
    importProgressUpdated(publicationId: $publicationId) {
      publicationId
      status
      totalPostsImported
      totalSubscribersImported
    }
  }
`

import { gql } from '@tellimer/graphql-client'
export * from '../common'

export const ONBOARDING_PROGRESS_COMMON_FIELDS = gql`
  fragment OnboardingProgressCommonFields on OnboardingProgress {
    id
    publication {
      id
    }
    completedPercentage
    isDismissed
    publicationStep {
      isSkipped
      isCompleted
    }
    brandingStep {
      isSkipped
      isCompleted
    }
    contactsStep {
      isSkipped
      isCompleted
    }
    verifyEmailStep {
      isSkipped
      isCompleted
    }
    publishFirstPostStep {
      isCompleted
    }
    gettingPaidStep {
      isSkipped
      isCompleted
    }
  }
`

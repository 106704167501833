import { gql } from '@tellimer/graphql-client'
import { query } from 'gql'
import { handleQueryResponseWithMeta } from 'gql/util'
import { PAGE_SIZE } from 'helper/constants'
import { SubscriberFilterParams, SubscriberSort } from 'types/subscriber.types'
import { ERROR_FIELDS, META_FIELDS } from '../fragments'

export const QUERY_LIST_SUBSCRIBER_IDS = gql`
  query ListSubscribers($where: SubscriberListWhereInput, $sort: [SubscriberListSorter], $limit: Int!, $after: String) {
    listSubscribers(where: $where, sort: $sort, limit: $limit, after: $after) {
      data {
        id
      }
      ${META_FIELDS}
      ${ERROR_FIELDS}
    }
  }
`

export type ListSubscriberIdsQueryType = {
  sort?: SubscriberSort
  limit?: number
  after?: string
  where?: SubscriberFilterParams
}

export const listSubscriberIdsQuery = async (filters: ListSubscriberIdsQueryType) => {
  const { sort = SubscriberSort.NEWEST, limit = PAGE_SIZE, after = '', where = {} } = filters

  const response = await query(QUERY_LIST_SUBSCRIBER_IDS, {
    sort: [sort],
    limit: limit,
    after,
    where,
  })

  return handleQueryResponseWithMeta(response, 'listSubscribers') as {
    meta: {
      cursor: string
      hasMoreItems: boolean
      totalCount: number
    }
    data: [{ id: string }]
  }
}

import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { handleQueryResponse } from '../../util'
import { ERROR_FIELDS, POST_COMMON_FIELDS } from '../fragments'
import { PostInput, PostData } from '../types'

export const MUTATION_CREATE_POST = gql`
  ${POST_COMMON_FIELDS}
  mutation CreatePost($data: PostInput!) {
    createPost(data: $data) {
      data {
        id
        ...PostCommonFields
      }
      ${ERROR_FIELDS}
    }
  }
`

export const createPostMutation = async (data: PostInput) => {
  if (!data) {
    throw new Error("Can't create a post without data")
  }

  // The empty string values are mandatory, without them things won't work
  // FIXME: We should update the types to reflect that
  const {
    title = '',
    subtitle = '',
    slug = '',
    body,
    featuredImagePresence,
    featuredImage,
    featuredImageCaption,
    emailSubject = '',
    publicationId,
    metaDescription,
    summary,
    postAccessId,
    useMetaDescription,
  } = data
  const res = await mutation(MUTATION_CREATE_POST, {
    data: {
      title,
      subtitle,
      slug,
      body,
      featuredImagePresence,
      featuredImage,
      featuredImageCaption,
      emailSubject,
      publicationId,
      summary,
      metaDescription,
      useMetaDescription,
      postAccessId,
    },
  })

  return handleQueryResponse(res, 'createPost') as PostData
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCreatorQuery } from 'gql/creator/queries'
import { hidePostInterstitialPageMutation } from 'gql/post/mutations'

// FIXME: This can be done form both post and publications settings but I think
// moving it to publication makes more sense since it is a pulbication setting.
// It lives here just because it was implemented first on post

export const updateHidePostInterstitial = createAsyncThunk(
  'postDetail/updateHidePostInterstitial',
  async (hide: boolean) => {
    try {
      await hidePostInterstitialPageMutation(hide)
      return await getCreatorQuery()
    } catch (e) {
      throw e
    }
  },
)

import { gql } from '@tellimer/graphql-client'
import { query } from 'gql'
import { handleQueryResponse } from 'gql/util'

import { ERROR_FIELDS } from '../fragments'

export const QUERY_GET_STRIPE_CONNECT_SETTINGS = gql`
  query GetStripeConnectSettings($publicationId: String!, $fromStepper: Boolean!) {
    getStripeConnectSettings(publicationId: $publicationId, fromStepper: $fromStepper) {
      data {
        isConnected
        accountName
        accountId
        stripeUrl
      }
      ${ERROR_FIELDS}
    }
  }
`

export const getStripeConnectSettingsQuery = async (
  publicationId: string,
  fromStepper: boolean,
) => {
  if (!publicationId) {
    throw new Error("Can't get Stripe Connect settings without a publication id")
  }

  const res = await query(QUERY_GET_STRIPE_CONNECT_SETTINGS, { publicationId, fromStepper })

  return handleQueryResponse(res, 'getStripeConnectSettings')
}

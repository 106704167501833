import { gql } from '@tellimer/graphql-client'
import { ERROR_FIELDS } from './fragments'

export const listWhatsNexts = gql`
  query ListWhatsNexts {
    listWhatsNexts {
      data {
        viewAllUrl
        links {
          title
          url
          thumbnailUrl
        }
      }
      ${ERROR_FIELDS}
    }
  }
`

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { merge } from 'lodash'
import { useDispatch } from 'react-redux'
import appReducer, { initialState as appInitialState } from './app/slice'
import authReducer, { initialState as authInitial } from './auth/slice'
import dashboardReducer, { initialState as dashboardInitial } from './dashboard/slice'
import onboardingProgressReducer, {
  initialState as onboardingProgressInitial,
} from './onboardingProgress/slice'
import onboardingReducer, { initialState as onboardingInitial } from './onboarding/slice'
import importSubscribersReducer, {
  initialState as importSubscribersInitial,
} from './importSubscribers/slice'
import postReducer, { initialState as postInitial } from './post/slice'
import postDetailReducer, { initialState as postDetailInitial } from './postDetail/slice'
import publicationReducer, { initialState as publicationInitial } from './publication/slice'
import subscriberReducer, { initialState as subscriberInitial } from './subscriber/slice'
import subscriberDetailReducer, {
  initialState as subscriberDetailInitial,
} from './subscriberDetail/slice'
import searchReducer, { initialState as searchInitial } from './search/slice'
import paymentReducer, { initialState as paymentInitial } from './payments/slice'

export type makeStoreType = {
  initialState?: any
}

export function makeStore(options: makeStoreType) {
  const preloadedState =
    options?.initialState &&
    merge(
      {
        app: appInitialState,
        auth: authInitial,
        dashboard: dashboardInitial,
        onboardingProgress: onboardingProgressInitial,
        onboarding: onboardingInitial,
        importSubscribers: importSubscribersInitial,
        post: postInitial,
        postDetail: postDetailInitial,
        publication: publicationInitial,
        subscriber: subscriberInitial,
        subscriberDetail: subscriberDetailInitial,
        search: searchInitial,
        payments: paymentInitial,
      },
      options.initialState,
    )

  return configureStore({
    reducer: {
      app: appReducer,
      auth: authReducer,
      dashboard: dashboardReducer,
      onboardingProgress: onboardingProgressReducer,
      onboarding: onboardingReducer,
      importSubscribers: importSubscribersReducer,
      post: postReducer,
      postDetail: postDetailReducer,
      publication: publicationReducer,
      subscriber: subscriberReducer,
      subscriberDetail: subscriberDetailReducer,
      search: searchReducer,
      payments: paymentReducer,
    },
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [
            'auth/setCreator',
            'auth/updateCreator/pending',
            'auth/updateCreator/fulfilled',
            'auth/updateCreator/rejected',
          ],
        },
      }),
  })
}

const store = makeStore({})

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types
export default store

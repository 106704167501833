import { createAsyncThunk } from '@reduxjs/toolkit'
import { getPostSlug } from './getPostSlug'

export const startCreatePost = createAsyncThunk(
  'postDetail/startCreatepost',
  async (template: { [x: string]: any }, { dispatch }) => {
    if (!template) return

    const { slug, title } = template

    const value = slug || title
    const result = await dispatch(getPostSlug(value)).unwrap()

    return {
      ...template,
      slug: result,
    }
  },
)

import React from 'react'

import { MenuIcon } from '@tellimer/ui/heroicons/outline'
import { Popover } from '@tellimer/ui/headless'

import ProfileMenu from './ProfileMenu'
import SearchInput from './SearchInput'
import NewPostButton from './NewPostButton'

const Header: React.FC = () => {
  return (
    <div className="lg:hidden flex flex-shrink-0 h-16 inset-x-0 top-0 z-0 bg-gray-700 shadow">
      <div className="max-w-[240px] flex-shrink-0">
        <ProfileMenu position="bottom" />
      </div>
      <div className="flex-1 flex justify-between items-center">
        <SearchInput />
        <div className="mx-4">
          <NewPostButton />
        </div>
      </div>

      <Popover.Button
        type="button"
        className="px-4 border-l border-gray-800 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-transparent"
      >
        <span className="sr-only">Open menu</span>
        <MenuIcon className="h-6 w-6" aria-hidden="true" />
      </Popover.Button>
    </div>
  )
}

export default Header

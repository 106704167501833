import { gql } from '@tellimer/graphql-client'
import { PostData } from '../types'
import { mutation } from 'gql'
import { ERROR_FIELDS, POST_COMMON_FIELDS } from '../fragments'
import { handleQueryResponse } from '../../util'

export const MUTATION_POST_UNPUBLISH = gql`
  ${POST_COMMON_FIELDS}
  mutation UnPublishPost($id: ID!) {
    unpublishPost(id: $id) {
      data {
        id
        ...PostCommonFields
      }
      ${ERROR_FIELDS}
    }
  }
`

export const unpublishPostMutation = async (id: string) => {
  if (!id) {
    throw new Error("Can't unpublish a post without id")
  }

  const res = await mutation(MUTATION_POST_UNPUBLISH, {
    id,
  })
  return handleQueryResponse(res, 'unpublishPost') as PostData
}

import { createSelector } from 'reselect'
import { AppState } from '../store'
import { PAGE_SIZE } from 'helper/constants'

const subscribers = (state: AppState) => state.subscriber.subscribers
export const selectTotalCountOfSubscribers = (state: AppState) => state.subscriber.totalCount
export const selectCurrentPage = (state: AppState) => state.subscriber.currentPage
export const selectSubscriptions = (state: AppState) => state.subscriber.subscriptions
export const selectCurrentPageSubscribers = createSelector(
  subscribers,
  selectCurrentPage,
  (subscribers, currentPage) =>
    subscribers?.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE),
)
export const selectSubscribersInit = (state: AppState) => state.subscriber.init

export const selectTotalSubscribersPerAccess = (state: AppState) => state.subscriber.totalPerAccess
export const selectExporting = (state: AppState) => state.subscriber.exportSubscribers.exporting
export const selectExportingSubscriptionInitiated = (state: AppState) =>
  state.subscriber.exportSubscribers.exportingSubscriptionInitiated
export const selectFilterParams = (state: AppState) => state.subscriber.filterParams
export const selectSort = (state: AppState) => state.subscriber.sort

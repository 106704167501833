import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { handleMutationResponse } from 'gql/util'
import { SubscriberSort } from 'types/subscriber.types'
import { ERROR_FIELDS } from '../fragments'

export const MUTATION_EXPORT_SUBSCRIBERS = gql`
  mutation exportSubscribers(
    $publicationId: String!
    $where: ExportContactsWhereInput
    $sort: [ExportContactsSorter]
  ) {
    exportContacts(data: { publicationId: $publicationId, where: $where, sort: $sort }) {
      data
      ${ERROR_FIELDS}
    }
  }
`

export type exportSubscribersVariablesType = {
  publicationId: string
  where: {
    subscriberId?: String
    search?: String
  }
  sort: SubscriberSort
}

export const exportSubscribersMutation = async (variables: exportSubscribersVariablesType) => {
  if (!variables) throw new Error("Can't export subscribers without variables")
  if (!variables.publicationId) throw new Error("Can't export subscribers without publication id")
  if (!variables.where) throw new Error("Can't export subscribers without where conditions")
  if (!variables.sort) throw new Error("Can't export subscribers without sorting")

  const response = await mutation(MUTATION_EXPORT_SUBSCRIBERS, variables)
  return handleMutationResponse(response, 'exportContacts') as any
}

export type GrowthDataType = {
  name: string | null
  subscribes: number
  unsubscribes: number
  overall: number
}

export enum GrowthDataFeedType {
  OVERALL = 'overall',
  SUBSCRIBES = 'subscribes',
  UNSUBSCRIBES = 'unsubscribes',
}

export type FetchAnalyticsType = {
  sort?: AvailableIntervalSort
  filterParams?: any
  limit?: number
  after?: string
}

export type WhatsNextLink = {
  title?: string
  url?: string
  thumbnailUrl?: string
}

export type WhatsNext = {
  viewAllUrl?: string
  links?: WhatsNextLink[]
}

export type AvailableInterval = {
  name: string
  value: string
}

export enum AvailableIntervalSort {
  NEWEST = 'createdOn_ASC',
}

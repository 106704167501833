import { createAsyncThunk } from '@reduxjs/toolkit'
import { deleteAccountMutation } from 'gql/creator'
import { mutation } from 'gql'

export const deleteAccount = createAsyncThunk('auth/deleteAccount', async () => {
  const { data, error } = await mutation(deleteAccountMutation)

  if (error || data?.deleteAccount?.error) {
    throw new Error(error?.message || data?.deleteAccount?.error?.message)
  }

  return data?.deleteAccount?.data
})

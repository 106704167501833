import { createAction } from '@reduxjs/toolkit'

export const setSearch = createAction('post/setSearch', (value?: string) => {
  if (!value || !value.trim()) {
    return {
      payload: {
        search: value,
      },
    }
  }

  const filter: {
    title_contains?: string
    isPublished?: boolean
  } = {}

  const words = value.toLowerCase().split(/\s+/gi)

  const nonSpecialWords = words.reduce((result: string[], word) => {
    if (word === 'draft') {
      filter.isPublished = false
      return result
    }

    if (word === 'published' && filter.isPublished !== false) {
      filter.isPublished = true
      return result
    }

    result.push(word)
    return result
  }, [])

  const title = nonSpecialWords.join(' ').trim()
  if (title) {
    filter.title_contains = title
  }

  return {
    payload: {
      search: value,
      filter,
    },
  }
})

import React, { Fragment } from 'react'
import Link from 'next/link'

import * as Glyph from '@tellimer/ui/Icon'

import { SearchResult, SearchResultType } from '../../../types/search.types'

export type SearchResultsListProps = {
  searchKey: string
  searchResults?: SearchResult[]
}

const SearchResultsList: React.FC<SearchResultsListProps> = ({
  searchResults = [],
  searchKey = '',
}) => {
  if (searchResults.length < 1 || !searchKey.trim()) return null

  return (
    <div className="w-screen lg:w-102 absolute z-10 -bottom-3.5 lg:-bottom-2 -left-[67px] lg:left-0 translate-y-full overflow-hidden ring-1 ring-black ring-opacity-5 shadow-lg rounded-b-md sm:rounded-md bg-white">
      {searchResults.map(({ id, title, type = SearchResultType.POST, url }, index) => {
        const Icon = type === SearchResultType.POST ? Glyph.Post : Glyph.User

        return (
          <Fragment key={id}>
            <Link href={url} passHref>
              <a
                className="flex items-center justify-between px-3 py-2.5 transition text-sm text-gray-900 hover:bg-gray-100 hover:text-accent-600"
                onMouseDown={e => e.preventDefault()}
              >
                <span className="block truncate">{title}</span>
                <Icon
                  className="h-5 ml-2.5 flex-shrink-0 stroke-gray-400 fill-gray-400"
                  title={type}
                />
              </a>
            </Link>
            {index < searchResults.length - 1 && <div className="border-b border-gray-200" />}
          </Fragment>
        )
      })}
    </div>
  )
}

export default SearchResultsList

import { createAsyncThunk } from '@reduxjs/toolkit'
import { SubscriberSort, SubscriberFilterParams } from 'types/subscriber.types'
import { PAGE_SIZE } from 'helper/constants'

import { fetchSubscribers } from './fetchSubscribers'

export type FetchPageSubscribersType = {
  sort: SubscriberSort
  filterParams?: SubscriberFilterParams
  page: number
}

export const fetchPageSubscribers = createAsyncThunk(
  'subscribers/fetchPage',
  async (payload: FetchPageSubscribersType, { getState, dispatch }) => {
    const { page, sort, filterParams } = payload
    const state = getState() as any

    if (page >= Math.ceil(state.subscriber.subscribers.length / PAGE_SIZE)) {
      const after = state.subscriber.cursor
      await dispatch(fetchSubscribers({ sort, filterParams, after, loadMore: true }))
    }

    return page
  },
)

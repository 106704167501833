import { Publication } from './publication.types'

export type EmailListFileContacts = {
  email?: string
  fullName?: string
}

export type EmailListFile = {
  name?: string
  status?: string
  totalContactsToImport?: number
  totalSkippedContacts?: number
  totalImportedContacts?: number
  errorMessage?: string
  contacts?: [EmailListFileContacts]
}

export type SubscriptionBilling = {
  billingInterval?: string
  viewSubscriberUrl?: string
  billingAmount?: number
  discountPercentage?: number
  signedUpAt?: string
  nextInvoiceAt?: string
  totalSpent?: number
}

export type SubscriptionAnalytics = {
  emailsReceived?: number
  emailsOpened?: number
  webViews?: number
}

export type SubscriptionActions = {
  canDelete: Boolean
  canDowngrade: Boolean
  canExport: Boolean
}

export type Subscription = {
  subscriptionType?: SubscriptionType
  createdOn?: string
  analytics?: SubscriptionAnalytics
  emailsReceived?: number
  emailsOpened?: number
  webViews?: number
  billing?: SubscriptionBilling
  status?: SubscriptionStatus
  hasOptedOut?: boolean
  actions?: SubscriptionActions
}

export type SubscriptionUpdated = {
  isConfirmed: boolean
  isSubscribed: boolean
  confirmedAt?: Date
}

export type EmailList = {
  id: string
  publications?: [Publication]
  file?: EmailListFile
}

export type Subscriber = {
  id: string
  createdOn: Date
  fullName?: string
  hasVerifiedEmail?: boolean
  hasRandomPassword?: boolean
  canChangePassword?: boolean
  email: string
  profilePicture?: string
  subscriptions?: Subscription[]
  currentSubscription?: Subscription
}

export type SubscriberFilterParams = {
  publicationId?: String
  search?: string
  postAccessId?: string
}

export enum SubscriberSort {
  NEWEST = 'createdOn_DESC',
  NAME = 'fullName_ASC',
  EMAIL = 'email_ASC',
}

export enum SubscriptionStatus {
  SUBSCRIBED = 'SUBSCRIBED',
  PAYMENT_FAILURE = 'PAYMENT_FAILURE',
  PENDING_DOWNGRADE = 'PENDING_DOWNGRADE',
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  PENDING_DELETION = 'PENDING_DELETION',
}

export enum SubscriptionType {
  FREE = 'FREE',
  CREATOR = 'CREATOR',
  PAID = 'PAID',
}

import { Publication } from 'types/publication.types'
import type { AppState } from '../store'
import { STATUS_FETCHING_PUBLICATION, STATUS_UPDATING_PUBLICATION } from './slice'

const BASE_DOMAIN = process.env.NEXT_PUBLIC_BASE_DOMAIN || 'localhost'

export const publicationSelector = (state: AppState) => state.publication
export const selectPublication = (state: AppState) => state.publication.publication as Publication
export const selectPublicationStatus = (state: AppState) =>
  state.publication.status as STATUS_UPDATING_PUBLICATION | STATUS_FETCHING_PUBLICATION
// FIXME: Change all of them to `selectX`
export const getDefaultEmailListId = (state: AppState) =>
  state.publication.publication?.defaultEmailList?.entryId

export const selectPublicationDomain = (state: AppState) => {
  const { publication } = state.publication
  if (BASE_DOMAIN === 'localhost') {
    return `http://${BASE_DOMAIN}:3003`
  }
  return publication?.domain?.isSubdomain
    ? `https://${publication?.domain?.name}.${BASE_DOMAIN}`
    : publication?.domain?.name
}

export const selectPublicationTestEmailIsEmailing = (state: AppState) =>
  state.publication?.isEmailing

export const selectPublicationHasPaidPackage = (state: AppState) =>
  !!state.publication?.publication?.hasPaidPackage

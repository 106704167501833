import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { handleMutationResponse } from 'gql/util'
import { ERROR_FIELDS } from '../fragments'

export const MUTATION_UPDATE_SUBSCRIBER = gql`
  mutation updateSubscriber($publicationId: ID!, $subscriberId: ID!, $fullName: String!) {
    updateSubscriber(publicationId: $publicationId, subscriberId: $subscriberId, data:{fullName: $fullName}) {
      data {
        id
      }
      ${ERROR_FIELDS}
    }
  }
`

export type updateSubscriberVariablesType = {
  publicationId: string
  subscriberId: string
  fullName: String
}

export const updateSubscriberMutation = async (variables: updateSubscriberVariablesType) => {
  if (!variables) throw new Error("Can't update subscriber without variables")
  if (!variables.publicationId) throw new Error("Can't update subscriber without publication id")
  if (!variables.subscriberId) throw new Error("Can't update subscriber without subscription id")
  if (!variables.fullName) throw new Error("Can't update subscriber without fullName")

  const response = await mutation(MUTATION_UPDATE_SUBSCRIBER, variables)
  return handleMutationResponse(response, 'updateSubscriber') as any
}

import { createAsyncThunk, AsyncThunkPayloadCreator, AsyncThunk } from '@reduxjs/toolkit'

export const createDebouncedAsyncThunk = <Returned, ThunkArg = void>(
  actionName: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg>,
  wait: number,
): AsyncThunk<Returned, ThunkArg, {}> => {
  let timer = 0
  let pendingResolve: ((value: boolean) => void) | null = null

  return createAsyncThunk<Returned, ThunkArg>(actionName, payloadCreator as never, {
    condition() {
      // We clear any previous timers plus we resolve as false any pending resolutions
      window.clearTimeout(timer)
      if (pendingResolve) {
        pendingResolve(false)
      }

      // We defer the execution of the action
      timer = window.setTimeout(() => {
        if (pendingResolve) pendingResolve(true)
        pendingResolve = null
      }, wait)

      return new Promise<boolean>(resolve => {
        pendingResolve = resolve
      })
    },
  })
}

export default createDebouncedAsyncThunk

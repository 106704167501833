import { createAsyncThunk } from '@reduxjs/toolkit'
import getSlug from 'slug'
import { NotificationLayout, NotificationManager } from '@tellimer/ui'
import { generatePostSlugMutation } from 'gql/post/mutations'
import { selectPublication } from 'redux/publication/selector'
import { AppState } from 'redux/store'

export const getPostSlug = createAsyncThunk(
  'posts/getPostSlug',
  async (slug: string, { getState }) => {
    const state = getState() as AppState
    const publication = selectPublication(state)

    try {
      if (!publication.id) {
        throw new Error('Missing publication id while .generating the post slug')
      }

      const value = getSlug(slug, '-')

      // This will take the proposed slug and return it as is or add the reuired suffix is there is another
      // post with the same slug on this publication
      const result = await generatePostSlugMutation(publication.id, value)
      return result
    } catch (e) {
      console.error(e)
      NotificationManager.create({
        type: 'error',
        layout: NotificationLayout.SIMPLE,
        title: 'Error',
        text: 'Something failed while generating the post slug',
      })
      throw e
    }
  },
)

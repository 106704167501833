import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCreatorQuery } from 'gql/creator/queries'
import { setPublication } from 'redux/publication/slice'
import { setImportDetails } from 'redux/importSubscribers/slice'

/**
 * Trigger a `getCreator` query
 */
export const fetchCreator = createAsyncThunk('auth/getCreator', async (_, { dispatch }) => {
  const creator = await getCreatorQuery()
  const { publication } = creator

  dispatch(setPublication(publication))

  if (publication.defaultEmailList?.file) {
    dispatch(setImportDetails(publication.defaultEmailList?.file))
  }

  return creator
})

import { createAsyncThunk } from '@reduxjs/toolkit'
import { SubscriberSort, SubscriberFilterParams } from 'types/subscriber.types'

import { PAGE_SIZE } from 'helper/constants'
import { listSubscribersQuery } from 'gql/subscriber/queries'
import { selectPublication } from 'redux/publication/selector'

export type FetchSubscribersType = {
  sort?: SubscriberSort
  filterParams?: SubscriberFilterParams
  limit?: number
  after?: string
  loadMore?: boolean
}

export const fetchSubscribers = createAsyncThunk(
  'subscribers/fetch',
  async (payload: FetchSubscribersType, { getState }) => {
    const state = getState() as any
    const publication = selectPublication(state)

    const {
      sort = state.auth.sort || SubscriberSort.NEWEST,
      filterParams,
      limit = PAGE_SIZE,
      after,
      loadMore = false,
    } = payload

    const where = { ...(filterParams || {}), publicationId: publication.id }

    const { data, meta } = await listSubscribersQuery({
      sort,
      limit,
      after,
      where,
    })

    return { data, sort, filterParams, loadMore, meta }
  },
)

import { createAsyncThunk } from '@reduxjs/toolkit'
import { NotificationLayout, NotificationManager } from '@tellimer/ui'
import { getPostPreviewQuery } from 'gql/post/queries'

export const fetchPostPreview = createAsyncThunk(
  'postDetail/fetchPostPreview',
  async (postId: string) => {
    try {
      const result = await getPostPreviewQuery(postId)
      return result || {}
    } catch (e) {
      console.error(e)
      NotificationManager.create({
        type: 'error',
        layout: NotificationLayout.SIMPLE,
        title: 'Error',
        text: 'Something failed while retrieving the post preview',
      })
      throw e
    }
  },
)

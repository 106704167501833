import { createAsyncThunk } from '@reduxjs/toolkit'
import { updatePostMutation } from 'gql/post/mutations'
import { PostInput, Post, PublishTo } from 'types/post.types'
import { selectPostDetails } from '../selector'

export const updatePost = createAsyncThunk(
  'postDetail/updatePost',
  async (payload: PostInput, { getState }: { getState: any }) => {
    const state = getState() as any
    const post = selectPostDetails(state)

    if (!post.id) {
      throw new Error("Can't update a post without an id")
    }

    if (payload.body) {
      payload.body = JSON.stringify(payload.body)
    }

    const result = await updatePostMutation({
      ...payload,
      id: post.id,
      publishTo: PublishTo.WEBSITE_AND_EMAIL,
    })

    return {
      ...result,
      body: result.body ? JSON.parse(result.body) : result.body,
    } as Post
  },
)

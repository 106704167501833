import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { ERROR_FIELDS, POST_COMMON_FIELDS } from '../fragments'
import { PostData } from '../types'
import { handleQueryResponse } from '../../util'
import { PublishTo } from 'types/post.types'

export const MUTATION_POST_PUBLISH = gql`
  ${POST_COMMON_FIELDS}
  mutation PublishPost($id: ID!, $publishTo: PublishTo!) {
    publishPost(id: $id, publishTo: $publishTo) {
      data {
        id
        ...PostCommonFields
      }
      ${ERROR_FIELDS}
    }
  }
`

export const publishPostMutation = async (id: string, publishTo: PublishTo) => {
  if (!id) {
    throw new Error("Can't publish a post without id")
  }

  const res = await mutation(MUTATION_POST_PUBLISH, {
    id,
    publishTo,
  })
  return handleQueryResponse(res, 'publishPost') as PostData
}

import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { handleMutationResponse } from 'gql/util'
import { ERROR_FIELDS } from '../fragments'

export const MUTATION_UPLOAD_CONTACTS = gql`
  mutation uploadContacts ($publicationId: ID!, $fileKey: String!) {
    uploadContacts(
      data: {
        publicationId: $publicationId
        fileKey: $fileKey
        isEmailRequired: true
        isFullNameRequired: false
      }
    ) {
      data
      ${ERROR_FIELDS}
    }
  }
`

export const uploadContacts = async (fileKey: string, publicationId: string) => {
  if (!publicationId) {
    throw new Error("Can't upload contacts for a publication without an id")
  }
  if (!fileKey) {
    throw new Error("Can't upload contacts without a file")
  }

  const res = await mutation(MUTATION_UPLOAD_CONTACTS, {
    fileKey,
    publicationId,
  })
  return handleMutationResponse(res, 'uploadContacts') as boolean
}

import React, { ReactElement, useEffect } from 'react'
import type { AppProps } from 'next/app'
import { Provider as ReduxProvider } from 'react-redux'
import store from 'redux/store'
import { NotificationContainer } from '@tellimer/ui/Notification'
import { Amplify } from 'aws-amplify'
import { initDatadogRUM } from 'lib/datadog-utils'
import AppWrapper from '../components/app/wrapper'
import { AMPLIFY_CONFIG } from '../config'
import ErrorBoundary from 'components/app/ErrorBoundary'
import { NextPageWithLayout } from 'types/common.types'

import 'styles/globals.css'
import 'styles/editor.css'
import 'react-loading-skeleton/dist/skeleton.css'
import { LDProvider } from 'launchdarkly-react-client-sdk'

import { useUser } from 'hooks/useUser'
import Layout from 'components/app/Layout'

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

Amplify.configure(AMPLIFY_CONFIG)

if (typeof window !== 'undefined') {
  // // NOTE: This is done to support preview on post via iframe with postMessage while using Safari.
  // // The preview page will itself do the same domain change so both will count as being on the same origin
  // // and we can cross communicate between them
  document.domain =
    document.domain === 'localhost'
      ? document.domain // This will be localhost
      : document.domain.split('.').splice(-2).join('.') // This will be tellimer.io or scriber.to
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // By default all the pages will load the default page layout but any page can specify a custom one
  // for example the post details doesn't have a sidebar so it has its own layout
  const pageLayout = Component.getLayout
    ? Component.getLayout
    : (page: ReactElement) => <Layout>{page}</Layout>

  const user = useUser()
  let ldUser

  if (user) {
    ldUser = {
      key: user.email,
      name: user.name,
      email: user.email,
    }
  }

  useEffect(() => {
    initDatadogRUM()
  }, [])

  return (
    <ErrorBoundary>
      <ReduxProvider store={store}>
        <LDProvider
          clientSideID={process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_SIDE_ID as string}
          user={ldUser}
          deferInitialization={true}
          options={{
            bootstrap: 'localStorage',
          }}
        >
          <AppWrapper>
            {pageLayout(<Component {...pageProps} />)}
            <NotificationContainer />
          </AppWrapper>
        </LDProvider>
      </ReduxProvider>
    </ErrorBoundary>
  )
}
export default MyApp

import { createAsyncThunk } from '@reduxjs/toolkit'
import { query as queryClient } from 'gql'
import { getCreator } from 'redux/auth/selector'
import { searchQuery } from 'gql/search'
import { PAGE_SIZE } from 'helper/constants'

type fetchSearchResultsProps = {
  query?: string
  limit?: number
  after?: string
}

export const fetchSearchResults = createAsyncThunk(
  'search/fetchSearchResults',
  async (payload: fetchSearchResultsProps, { getState }) => {
    const state = getState() as any
    const creator = getCreator(state)
    const { id } = creator?.publication || {}
    const { query, limit = PAGE_SIZE, after } = payload

    const { data, error } = await queryClient(searchQuery, {
      publicationId: id,
      query,
      limit,
      after,
    })

    if (error) {
      throw new Error(error.message)
    }

    if (data.search?.error) {
      throw new Error(data.search?.error)
    }

    return data.search
  },
)

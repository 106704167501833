import { createSlice } from '@reduxjs/toolkit'
import { ImportStatus } from '@tellimer/ui/ImportContacts'
import {
  uploadSubscribers,
  ImportDetails,
  setImportFileDetails,
  setIsImportInProgress,
} from './uploadSubscribers'

import { setImportSubscribersDetails } from './initImportSubscription'

import { updateImportStatus } from './updateImportStatus'

export interface SubscriberState {
  isImportingInProgress: boolean
  lastError: any
  import: ImportDetails
}

export const initialState: SubscriberState = {
  isImportingInProgress: false,
  lastError: '',
  import: {
    fileDetails: null,
    status: ImportStatus.NOT_STARTED,
    totalContactsToImport: 0,
    totalSkippedContacts: 0,
    totalImportedContacts: 0,
  },
}

export const importSubscribersSlice = createSlice({
  name: 'subscribers',
  initialState,
  reducers: {
    setImportDetails: (state, action) => {
      const { payload } = action
      state.isImportingInProgress =
        payload.status &&
        payload.status !== ImportStatus.NOT_STARTED &&
        payload.status !== ImportStatus.IMPORTED &&
        // FIXME: In theory upload means there is an import in progress but currently the publication
        // doesn't return any file details for the import file when we fetch. Since we need the filename
        // to trigger the import to begin with it is not possible to resume the import. The only thing
        // we can do at the moment is to count UPLOADED as a non valid state and effectively discard that
        // file
        // DEZ: payload.name should provide the detail requested in the comment above(to be passed on as fileKey, albeit not sure why this is needed)
        payload.status !== ImportStatus.UPLOADED &&
        payload.status !== ImportStatus.CANCELLED
      state.import = {
        ...state.import,
        ...payload,
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(setIsImportInProgress, (state, action) => {
        state.isImportingInProgress = action.payload
      })
      .addCase(updateImportStatus, (state, action) => {
        state.import.status = action.payload
      })
      .addCase(setImportFileDetails, (state, action) => {
        state.import.fileDetails = action.payload
      })
      .addCase(setImportSubscribersDetails, (state, action) => {
        const { payload } = action
        state.import = {
          ...state.import,
          ...payload,
        }
      })
      .addCase(uploadSubscribers.rejected, (state, action) => {
        state.isImportingInProgress = false
        state.lastError = action.payload
      })
  },
})

export const { setImportDetails } = importSubscribersSlice.actions
export default importSubscribersSlice.reducer

import { gql } from '@tellimer/graphql-client'
export * from '../common'
export * from '../publication/fragments'

export const CREATOR_COMMON_FIELDS = gql`
  fragment CreatorCommonFields on Creator {
    fullName
    email
    profilePicture
    canChangePassword
    canDeleteAccount
    canChangeEmail
    hasVerifiedEmail
    hasClickedAddContentButton
    hasCompletedOnboarding
    showPostInterstitialPage
    defaultPostAccessType {
      id
      name
      hasPaywall
      isDefault
    }
  }
`

import { createAsyncThunk } from '@reduxjs/toolkit'
import { OnboardingStepTypes } from 'types/onboarding.types'

export const completeOnboardingStep = createAsyncThunk(
  'onboardingProgress/completeOnboardingStep',
  async (step: OnboardingStepTypes) => {
    const data = {
      isCompleted: true,
    } as any

    if (step !== OnboardingStepTypes.POST) {
      data.isSkipped = false
    }

    return { step, data }
  },
)

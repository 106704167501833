import { gql } from '@tellimer/graphql-client'
import { PostData, PostInput } from '../types'
import { mutation } from 'gql'
import { ERROR_FIELDS, POST_COMMON_FIELDS } from '../fragments'
import { handleQueryResponse } from '../../util'

export const MUTATION_UPDATE_POST = gql`
  ${POST_COMMON_FIELDS}
  mutation UpdatePost($id: ID!, $data: PostInput!) {
    updatePost(id: $id, data: $data) {
      data {
        id
        ...PostCommonFields
      }
      ${ERROR_FIELDS}
    }
  }
`

export const updatePostMutation = async (data: PostInput) => {
  if (!data) {
    throw new Error("Can't create a post without data")
  }

  const {
    id,
    title,
    subtitle,
    slug,
    body,
    featuredImagePresence,
    featuredImage,
    featuredImageCaption,
    emailSubject,
    publishTo,
    summary,
    useMetaDescription,
    metaDescription,
    postAccessId,
  } = data
  const res = await mutation(MUTATION_UPDATE_POST, {
    id,
    data: {
      title: title || '',
      subtitle: subtitle || '',
      slug: slug || '',
      body,
      featuredImagePresence,
      featuredImage,
      featuredImageCaption,
      emailSubject: emailSubject || '',
      publishTo,
      summary: summary || '',
      metaDescription: metaDescription || '',
      useMetaDescription: useMetaDescription,
      postAccessId: postAccessId || '',
    },
  })
  return handleQueryResponse(res, 'updatePost') as PostData
}

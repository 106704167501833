const LEVELS = ['debug', 'info', 'warn', 'error']

// eslint-disable-next-line @typescript-eslint/no-empty-function
const NOOP = function () {}

const METHODS = {
  debug:
    (name: string) =>
    (...args: any[]) =>
      console.log(`[${name}] >`, ...args),

  info:
    (name: string) =>
    (...args: any[]) =>
      console.info(`[${name}] >`, ...args),

  warn:
    (name: string) =>
    (...args: any[]) =>
      console.warn(`[${name}] >`, ...args),

  error:
    (name: string) =>
    (...args: any[]) =>
      console.error(`[${name}] >`, ...args),
} as Record<string, CallableFunction>

export const getLogger = (
  name = '',
  { disabled = false }: { disabled: boolean } = { disabled: false },
): Console => {
  if (disabled) {
    return {
      ...console,
      ...Object.keys(console).reduce((result, key) => {
        // @ts-ignore
        if (typeof console[key] === 'function' || METHODS[key]) {
          result[key] = NOOP
        } else {
          // @ts-ignore
          result[key] = console[key]
        }
        return result
      }, {} as any),
    }
  }

  if (typeof window === undefined) return console

  const debugLevel = localStorage.getItem('__SCRIBER_DEBUG__')
  const levelIndex = debugLevel ? LEVELS.indexOf(debugLevel) : 2

  return {
    ...console,
    ...LEVELS.reduce((result: Record<string, CallableFunction>, key, index) => {
      result[key] = index >= levelIndex ? METHODS[key](name) : NOOP
      return result
    }, {}),
    group: (...args) =>
      levelIndex > 0 ? NOOP : console.group(`[${name}] > ${args[0]}`, ...args.splice(1)),
    groupEnd: () => (levelIndex > 0 ? NOOP : console.groupEnd()),
  }
}

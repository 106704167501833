import { gql } from '@tellimer/graphql-client'

export const updateOnboardingProgressMutation = gql`
  mutation UpdateOnboardingProgress(
    $publicationId: ID!
    $completedPercentage: String!
    $isDismissed: Boolean!
    $publicationStepSkipped: Boolean!
    $publicationStepCompleted: Boolean!
    $brandingStepSkipped: Boolean!
    $brandingStepCompleted: Boolean!
    $contactsStepSkipped: Boolean!
    $contactsStepCompleted: Boolean!
    $verifyEmailStepSkipped: Boolean!
    $verifyEmailStepCompleted: Boolean!
    $gettingPaidStepSkipped: Boolean!
    $gettingPaidStepCompleted: Boolean!
    $publishFirstPostStepCompleted: Boolean!
    $totalSuccessfullyImportedContacts: Int
    $totalSkippedContacts: Int
    $totalContacts: Int
  ) {
    updateOnboardingProgress(
      publicationId: $publicationId
      completedPercentage: $completedPercentage
      isDismissed: $isDismissed
      publicationStepSkipped: $publicationStepSkipped
      publicationStepCompleted: $publicationStepCompleted
      brandingStepSkipped: $brandingStepSkipped
      brandingStepCompleted: $brandingStepCompleted
      contactsStepSkipped: $contactsStepSkipped
      contactsStepCompleted: $contactsStepCompleted
      verifyEmailStepSkipped: $verifyEmailStepSkipped
      verifyEmailStepCompleted: $verifyEmailStepCompleted
      gettingPaidStepSkipped: $gettingPaidStepSkipped
      gettingPaidStepCompleted: $gettingPaidStepCompleted
      publishFirstPostStepCompleted: $publishFirstPostStepCompleted
      totalSuccessfullyImportedContacts: $totalSuccessfullyImportedContacts
      totalSkippedContacts: $totalSkippedContacts
      totalContacts: $totalContacts
    ) {
      publicationId
      completedPercentage
      isDismissed
      publicationStepSkipped
      publicationStepCompleted
      brandingStepSkipped
      brandingStepCompleted
      contactsStepSkipped
      contactsStepCompleted
      verifyEmailStepSkipped
      verifyEmailStepCompleted
      gettingPaidStepSkipped
      gettingPaidStepCompleted
      publishFirstPostStepCompleted
      totalSuccessfullyImportedContacts
      totalSkippedContacts
      totalContacts
    }
  }
`

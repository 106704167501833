import { createAsyncThunk } from '@reduxjs/toolkit'
import { ScriberFileType, uploadFile } from '@tellimer/file-upload'
import { getClient, GQL_CLIENT_TYPES } from 'gql'

export const uploadAvatar = createAsyncThunk('auth/uploadAvatar', async (file: File) => {
  if (!file) return

  const gqlClient = getClient(GQL_CLIENT_TYPES.PRIVATE)

  const { key: src } = await uploadFile(file, gqlClient, {
    fileType: ScriberFileType.PROFILE_PICTURE,
  })

  return src
})

import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { handleQueryResponse } from 'gql/util'
import { SubscriptionType } from 'types/payment.types'
import { ERROR_FIELDS } from '../fragments'

export const MUTATION_SAVE_SUBSCRIPTION_PACKAGE = gql`
  mutation SaveSubscriptionPackage($data: SaveSubscriptionPackageInput!) {
    saveSubscriptionPackage(data: $data) {
      data
      ${ERROR_FIELDS}
    }
  }
`

export type SaveSubscriptionPackageInput = {
  publicationId: string
  type: SubscriptionType
  price?: number
  description?: string
  isEnabled?: boolean
  annualDiscount?: {
    name: string
    value: number
  }
}

export const saveSubscriptionPackageMutation = async ({
  publicationId,
  type,
  price,
  description,
  isEnabled,
  annualDiscount,
}: SaveSubscriptionPackageInput) => {
  if (!publicationId) {
    throw new Error("Can't save a subscription package without a publication")
  }
  if (!type) {
    throw new Error("Can't save a subscription package without an id")
  }

  const res = await mutation(MUTATION_SAVE_SUBSCRIPTION_PACKAGE, {
    data: {
      publicationId,
      subscriptionTypeId: type,
      price,
      description,
      isEnabled,
      annualDiscount,
    },
  })
  return handleQueryResponse(res, 'saveSubscriptionPackage')
}

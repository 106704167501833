import { AppState } from '../store'
import { PostDetailActions } from './const'

export const selectPostDetails = (state: AppState) => state.postDetail.details || {}

export const selectPostIsLoading = (state: AppState) => state.postDetail.isLoading
export const selectPostIsSaving = (state: AppState) => state.postDetail.isSaving
export const selectPostIsPublishing = (state: AppState) => state.postDetail.isPublishing
export const selectPostIsEmailing = (state: AppState) => state.postDetail.isEmailing

/**
 * Return true if the post is doing any header action, saving, publishing, sending email or deleting
 */
export const selectPostIsBusy = (state: AppState) =>
  state.postDetail.isSaving ||
  state.postDetail.isPublishing ||
  state.postDetail.isEmailing ||
  state.postDetail.isDeleting

export const selectPostAction = (state: AppState) => state.postDetail.action
export const selectPostIsCreate = (state: AppState) =>
  state.postDetail.action === PostDetailActions.CREATE
export const selectPostIsUpdate = (state: AppState) =>
  state.postDetail.action === PostDetailActions.UPDATE

export const selectPostShowPreview = (state: AppState) => state.postDetail.showPreview
export const selectPostPreviewIsLoading = (state: AppState) => state.postDetail.isLoadingPreview
export const selectPostPreview = (state: AppState) => state.postDetail.preview

export const selectIsDeleting = (state: AppState) => state.postDetail.isDeleting

export const selectPostAccessTypes = (state: AppState) => state.postDetail.postAccessTypes
export const selectHasMultipleAccessTypes = (state: AppState) =>
  (state.postDetail?.postAccessTypes?.length || 0) > 1

export const selectPostAccess = (state: AppState) => state.postDetail.postAccess

export const selectIsLoadingPostAccessTypes = (state: AppState) =>
  state.postDetail.isLoadingPostAccessTypes

export const selectDefaultPostAccess = (state: AppState) => state.postDetail.defaultPostAccess

export const selectPostIsReady = (state: AppState) =>
  !state.postDetail.isLoading && !state.postDetail.isLoadingPostAccessTypes

export const selectShowPostAccessHighlight = (state: AppState) =>
  state.postDetail.showPostAccessHighlight

import { LoadingBar, LogoWithText } from '@tellimer/ui'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import React, { useEffect } from 'react'
import { initApp } from 'redux/app/initApp'
import { selectAppIsFailed, selectAppIsLoaded } from 'redux/app/appSelector'
import ErrorComponent from './ErrorComponent'
// import { initImportProgressSubscription } from 'redux/publication/initImportProgressSubscription'

export type AppWrapperProps = {
  children?: React.ReactNode
}

const AppWrapper = (props: AppWrapperProps) => {
  const dispatch = useAppDispatch()
  const isLoaded = useAppSelector(selectAppIsLoaded)
  const isFailed = useAppSelector(selectAppIsFailed)

  useEffect(() => {
    dispatch(initApp()).unwrap()
  }, [dispatch])

  // useEffect(() => {
  //   if (isLoaded) {
  //     console.log('setting up subscription')
  //     dispatch(initImportProgressSubscription())
  //       .unwrap()
  //       .then(r => {
  //         console.log(r)
  //       })
  //       .catch(err => {
  //         console.error('something went wrong somehow', err)
  //       })
  //   }
  // }, [dispatch, isLoaded])

  if (!isLoaded) {
    return (
      <div className="fixed flex items-center justify-center w-full h-full">
        <LoadingBar size="lg" />
      </div>
    )
  }

  if (isFailed) {
    return (
      <>
        <LogoWithText className="h-5 text-gray-900 fixed top-6 left-6" />
        <ErrorComponent />
      </>
    )
  }

  // FIXME: WE only need this because posts is doing `useMutation` instead of using redux, once that is
  // fixed we can remove this
  return <>{props.children}</>
}

export default AppWrapper

import { createAsyncThunk } from '@reduxjs/toolkit'
import { query } from 'gql'
import { listWhatsNexts } from 'gql/dashboard'

/**
 * This is the action that will take a processed file and will start to get the subscribers from that
 * file into the publication.
 *
 */
export const fetchWhatsNext = createAsyncThunk('dashboard/fetchWhatsNext', async () => {
  const { data, error } = await query(listWhatsNexts)

  if (data?.listWhatsNexts?.error || error) {
    throw new Error(data?.listWhatsNexts?.error?.message || error?.message)
  }

  return data?.listWhatsNexts.data
})

import { createSlice } from '@reduxjs/toolkit'

import { SearchResult } from '../../types/search.types'
import { fetchSearchResults } from './actions'

export enum STATUS_FETCHING_SEARCH {
  FETCHING = 'fetching_search',
  FETCHED = 'fetched_search',
  FAILED = 'fetch_failed',
}

export interface SearchState {
  searchResults?: SearchResult[]
  status?: STATUS_FETCHING_SEARCH
  cursor: string
  hasMoreItems: boolean
  totalCount: number
  error: string | undefined
}

export const initialState: SearchState = {
  searchResults: undefined,
  cursor: '',
  hasMoreItems: false,
  totalCount: 0,
  status: undefined,
  error: '',
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    resetSearchResults: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSearchResults.pending, state => {
        state.status = STATUS_FETCHING_SEARCH.FETCHING
        state.error = ''
      })
      .addCase(fetchSearchResults.fulfilled, (state, action) => {
        const { data, meta } = action.payload
        state.status = STATUS_FETCHING_SEARCH.FETCHED
        state.error = ''
        state.searchResults = data
        state.totalCount = meta.totalCount
        state.cursor = meta.cursor
        state.hasMoreItems = meta.hasMoreItems
      })
      .addCase(fetchSearchResults.rejected, (state, action) => {
        state.status = STATUS_FETCHING_SEARCH.FAILED
        state.error = JSON.stringify(action.error)
      })
  },
})

export { fetchSearchResults }
export const { resetSearchResults } = searchSlice.actions
export default searchSlice.reducer

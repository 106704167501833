import { gql } from '@tellimer/graphql-client'

export const exportSubscribersSubscription = gql`
  subscription exportContactsUpdatedRealtime($publicationId: ID!) {
    exportContactsUpdated(publicationId: $publicationId) {
      publicationId
      downloadLink
      errorMessage
      status
    }
  }
`

import { createAsyncThunk } from '@reduxjs/toolkit'
import { optOutMutation } from 'gql/subscriber/mutations/optout'
import { selectPublication } from '../../publication/selector'
import { selectSubscriberDetails } from '../selector'
import { getSubscriberQuery } from 'gql/subscriber'

export const setSubscriberOptedOut = createAsyncThunk(
  'subscriber/setSubscriberOptedOut',
  async (hasOptedOut: boolean, { getState }) => {
    const state = getState() as any
    const publication = selectPublication(state)
    const subscriber = selectSubscriberDetails(state)

    if (!subscriber || !publication) {
      throw new Error("Can't optOut without a subscriber or publication")
    }

    await optOutMutation(subscriber.id, publication.id, hasOptedOut)

    // We do the querty and not the aciton since we don't want to trigger onload, really
    // what we want is to update the optOut but we pull it from the subscriber object so
    // we need to fetch it all
    return await getSubscriberQuery(subscriber.id, publication.id)
  },
)

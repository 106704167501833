import { gql } from '@tellimer/graphql-client'
import { query } from 'gql'
import { PostData } from '../types'
import { ERROR_FIELDS, POST_COMMON_FIELDS } from '../fragments'
import { handleQueryResponse } from '../../util'

export const QUERY_GET_POST = gql`
  ${POST_COMMON_FIELDS}
  query GetPost($id: ID) {
    getPost(id: $id) {
      data {
        id
        ...PostCommonFields
      }
      ${ERROR_FIELDS}
    }
  }
`
export const getPostQuery = async (id: string) => {
  if (!id) {
    throw new Error("Can't get post details without an id")
  }

  const res = await query(QUERY_GET_POST, { id })

  return handleQueryResponse(res, 'getPost') as PostData
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { createPostMutation } from 'gql/post/mutations'
import { selectPublication } from 'redux/publication/selector'
import { PostInput } from 'types/post.types'

export const createPost = createAsyncThunk(
  'postDetail/createPost',
  async (payload: PostInput, { getState }: { getState: any }) => {
    const state = getState()
    const { id: publicationId } = selectPublication(state)

    if (payload.body) {
      payload.body = JSON.stringify(payload.body)
    }

    // We do not do a try/catch since this will be called via savePost and there we have one already
    const result = await createPostMutation({
      ...payload,
      // we should only need to pass publication id on creation
      publicationId,
    })

    return {
      ...result,
      body: result.body ? JSON.parse(result.body) : result.body,
    }
  },
)

import { createAsyncThunk } from '@reduxjs/toolkit'
import { NotificationLayout, NotificationManager } from '@tellimer/ui'
import { publishPostMutation } from 'gql/post/mutations'
import { PublishTo } from 'types/post.types'

export const publishPost = createAsyncThunk(
  'postDetail/publishPost',
  async ({ id, publishTo }: { id: string; publishTo: PublishTo }) => {
    try {
      await publishPostMutation(id, publishTo)
    } catch (e) {
      console.error(e)
      NotificationManager.create({
        type: 'error',
        layout: NotificationLayout.SIMPLE,
        title: 'Error',
        text: 'Something failed while publishing the post',
      })
      throw e
    }
  },
)

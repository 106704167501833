import { createAsyncThunk } from '@reduxjs/toolkit'

import { getStripeConnectSettingsQuery, listSubscriptionTypesQuery } from 'gql/payments/queries'
import { listPostAccessTypesQuery } from 'gql/post/queries'
import { listSubscriberIdsQuery } from 'gql/subscriber/queries'
import { selectPublication } from 'redux/publication/selector'

export const initPaymentsSettings = createAsyncThunk(
  'payments/initPaymentsSettings',
  async (fromStepper: boolean, { getState }) => {
    const state = getState() as any
    const publication = selectPublication(state)

    const settings = await getStripeConnectSettingsQuery(publication.id, fromStepper)

    let types
    if (settings.isConnected) {
      types = await listSubscriptionTypesQuery(publication.id)
    }

    const postAccess = await listPostAccessTypesQuery(publication.id)
    const paidPostAccess = postAccess.find(item => item.hasPaywall)

    let hasPaidSubscribers = false
    if (paidPostAccess) {
      const where = { postAccessId: paidPostAccess.id, publicationId: publication.id }

      const { meta } = await listSubscriberIdsQuery({ where })

      hasPaidSubscribers = meta.totalCount > 0
    }

    return {
      types,
      settings,
      hasPaidSubscribers,
    }
  },
)

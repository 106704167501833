import { Client, TypedDocumentNode } from '@tellimer/graphql-client'
import { pipe, subscribe } from 'wonka'

export enum GQL_CLIENT_TYPES {
  WS = 'ws',
  PRIVATE = 'private',
}

export const clients = {} as Record<GQL_CLIENT_TYPES, Client>

export const setClient = (type: GQL_CLIENT_TYPES, client: Client) => (clients[type] = client)
export const getClient = (type: GQL_CLIENT_TYPES): Client => clients[type]

export type QueryOptions = {
  clientType: GQL_CLIENT_TYPES
}

/**
 * Execute the given query using by default the read client
 * @async
 * @param gqlQuery
 * @param variables
 * @param options
 * @returns
 */
export const query = (
  gqlQuery: TypedDocumentNode<any, object>,
  variables?: object,
  options?: QueryOptions,
) => {
  const { clientType } = options || {}
  const client = clients[clientType || GQL_CLIENT_TYPES.PRIVATE] as Client
  if (!client) throw new Error(`Client "${clientType}" is not initialized yet`)

  return client.query(gqlQuery, variables).toPromise()
}

export type MutationOptions = {
  clientType: GQL_CLIENT_TYPES
}

/**
 * Execute the given mutation using by default the main graphql client
 * @async
 * @param gqlQuery
 * @param variables
 * @param options
 * @returns
 */
export const mutation = (
  gqlQuery: TypedDocumentNode<any, object>,
  variables?: object,
  options?: MutationOptions,
) => {
  const { clientType } = options || {}
  const client = clients[clientType || GQL_CLIENT_TYPES.PRIVATE] as Client
  if (!client) throw new Error(`Client "${clientType}" is not initialized yet`)

  return client.mutation(gqlQuery, variables).toPromise()
}

export type SubscriptionOptions = {
  clientType: GQL_CLIENT_TYPES
}

/**
 * Subscribe to the given query using the web socket client
 * @param gqlQuery
 * @param variables
 * @param callback
 * @returns {Subscription}
 */
export const subscription = (
  gqlQuery: TypedDocumentNode<any, object>,
  variables: object,
  callback: CallableFunction,
) => {
  const client = clients[GQL_CLIENT_TYPES.WS] as Client
  if (!client) throw new Error(`Client "ws" is not initialized yet`)

  return pipe(
    client.subscription(gqlQuery, variables),
    subscribe((result: any) => {
      callback(result)
    }),
  )
}

import React, { ReactNode } from 'react'

import ErrorBoundary from 'components/app/ErrorBoundary'
import Sidebar from './sidebar'
import { PageHeader } from 'components/common/PageHeader'

export interface LayoutProps {
  title?: string
  actions?: ReactNode
  children?: ReactNode
}

const Layout = ({ title, children, actions }: LayoutProps) => {
  return (
    <ErrorBoundary>
      <div className="flex flex-col lg:flex-row items-stretch relative">
        <Sidebar />

        <div className="flex-1">
          {title ? <PageHeader name={title}>{actions}</PageHeader> : null}
          {children}
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default Layout

import { createAsyncThunk } from '@reduxjs/toolkit'

import { listPostsQuery } from 'gql/post/queries'
import { PostSort, PostFilterParams } from 'types/post.types'
import { PAGE_SIZE } from 'helper/constants'
import { selectPublication } from 'redux/publication/selector'

export type FetchPostsType = {
  sort?: PostSort
  filterParams?: PostFilterParams
  limit?: number
  after?: string
  loadMore?: boolean
}

export const fetchPosts = createAsyncThunk(
  'posts/fetch',
  async (payload: FetchPostsType, { getState }) => {
    const state = getState() as any
    const publication = selectPublication(state)
    const {
      sort = state.auth.sort || PostSort.LATEST_PUBLISHED,
      filterParams = state.post.filterParams,
      limit = PAGE_SIZE,
      after,
      loadMore = false,
    } = payload

    const where = { ...(filterParams || {}), publication: publication.id }
    try {
      const result = await listPostsQuery({
        sort: [sort],
        limit: limit,
        after,
        where,
      })
      return { data: result.data, meta: result.meta, sort, filterParams, loadMore }
    } catch (e) {
      throw e
    }
  },
)

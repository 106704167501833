import { createSelector } from 'reselect'
import { AppState } from '../store'
import { PAGE_SIZE } from 'helper/constants'

export const getLatestPosts = (state: AppState) => state.post.latestPosts
export const getLatestPostsTotalCount = (state: AppState) => state.post.totalCount
const posts = (state: AppState) => state.post.posts
export const selectCurrentPage = (state: AppState) => state.post.currentPage
export const selectCurrentPagePosts = createSelector(
  posts,
  selectCurrentPage,
  (posts, currentPage) => posts?.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE),
)
export const selectPostsInit = (state: AppState) => state.post.init
export const selectPostFetching = (state: AppState) => state.post.fetching
export const selectPostFetched = (state: AppState) => state.post.fetched
export const selectHasPublishedPosts = (state: AppState) => state.post.hasPublishedPosts
export const selectPublishedPosts = (state: AppState) => state.post.publishedPosts

export const selectPostsFilter = (state: AppState) => state.post.filter
export const selectPostsQuerySort = (state: AppState) => state.post.querySort
export const selectPosts = (state: AppState) => state.post.posts
export const selectIsFetchingPosts = (state: AppState) => state.post.isFetchingPosts
export const selectPostsTotalCount = (state: AppState) => state.post.totalCount
export const selectPostsCursor = (state: AppState) => state.post.cursor
export const selectPostsNextCursor = (state: AppState) => state.post.nextCursor
export const selectPostsPreviousCursor = (state: AppState) => state.post.previousCursor
export const selectHasMoreRecords = (state: AppState) => state.post.hasMoreRecords
export const selectPostsStartindex = (state: AppState) => state.post.startIndex
export const selectPostsSearch = (state: AppState) => state.post.search

import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { handleQueryResponse } from 'gql/util'
import { Creator, UpdateableCreatorFields } from 'types/creator.types'
import { ERROR_FIELDS, CREATOR_COMMON_FIELDS } from '../fragments'

export const MUTATION_UPDATE_CREATOR = gql`
  mutation UpdateCreator($data: UpdateCreatorInput) {
    creator: updateCreator(data: $data) {
      data {
        id
        ...CreatorCommonFields
      }
      ${ERROR_FIELDS}
      ${CREATOR_COMMON_FIELDS}
    }
  }
`

export const updateCreatorMutation = async (data: Partial<UpdateableCreatorFields>) => {
  if (!data) {
    throw new Error("Can't update a creator without data")
  }

  const {
    fullName,
    email,
    profilePicture,
    hasClickedAddContentButton,
    hasSeenPostAccessHighlight,
    defaultPostAccessTypeId,
  } = data

  const res = await mutation(MUTATION_UPDATE_CREATOR, {
    data: {
      fullName,
      email,
      profilePicture,
      hasClickedAddContentButton,
      hasSeenPostAccessHighlight,
      defaultPostAccessTypeId,
    },
  })

  return handleQueryResponse(res, 'creator') as Creator
}

import { gql } from '@tellimer/graphql-client'
import { mutation } from 'gql'
import { handleMutationResponse } from 'gql/util'
import { ERROR_FIELDS } from '../fragments'

export const MUTATION_CANCEL_CONTACTS_IMPORT = gql`
  mutation cancelContactsImport ($publicationId: ID!) {
    cancelContactsImport(publicationId: $publicationId) {
      data
      ${ERROR_FIELDS}
    }
  }
`

export const cancelContactsImport = async (publicationId: string) => {
  if (!publicationId) {
    throw new Error("Can't cancel uploading of contacts for a publication without an id")
  }

  const res = await mutation(MUTATION_CANCEL_CONTACTS_IMPORT, {
    publicationId,
  })
  return handleMutationResponse(res, 'cancelContactsImport') as boolean
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { saveAs } from 'file-saver'
import { NotificationLayout, NotificationManager } from '@tellimer/ui'
import { subscription } from 'gql'
import { exportSubscribersSubscription } from 'gql/subscriber/subscriptions/exportSubscribersSubscription'
import { selectPublication } from 'redux/publication/selector'
import { setExporting } from 'redux/subscriber/slice'

export const initExportSubscribersSubscription = createAsyncThunk(
  'subscribers/initExportSubscribersSubscription',
  async (_, { getState, dispatch }) => {
    const state = getState() as any
    const publication = selectPublication(state)

    subscription(
      exportSubscribersSubscription,
      { publicationId: publication.id },
      async (res: any) => {
        try {
          if (res.error) {
            throw new Error(res.error)
          }

          const { exportContactsUpdated } = res.data
          console.log('>>> Updated exporting progress', exportContactsUpdated)

          if (exportContactsUpdated.status === 'EXPORTED') {
            saveAs(exportContactsUpdated.downloadLink, 'subscribers.csv')

            NotificationManager.create({
              type: 'success',
              layout: NotificationLayout.ACTIONS_BELOW,
              title: 'Subscribers successfully exported',
              text: 'Your .CSV file should have been automatically downloaded.',
              actionTitle: 'Download',
              onAction: () => {
                saveAs(exportContactsUpdated.downloadLink, 'subscribers.csv')
              },
            })
            dispatch(setExporting(false))
          }
        } catch (err) {
          console.error(err)
          NotificationManager.create({
            type: 'error',
            layout: NotificationLayout.CONDENSED,
            title: 'An error occurred while exporting your subscribers',
          })
          return
        }
      },
    )
  },
)

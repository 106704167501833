import { createAsyncThunk } from '@reduxjs/toolkit'

import { listPostsQuery } from 'gql/post/queries'
import { PostSort, PostFilterParams } from 'types/post.types'
import { PAGE_SIZE } from 'helper/constants'
import { selectPublication } from 'redux/publication/selector'
import { selectPostsCursor, selectPostsFilter, selectPostsQuerySort } from '../selector'
import { getLogger } from 'helper/logger'

export type FetchPostsType = {
  sort?: PostSort
  filterParams?: PostFilterParams
  limit?: number
  after?: string
  loadMore?: boolean
}

const safeParse = (value: string) => {
  try {
    return JSON.parse(value)
  } catch (e) {
    return null
  }
}

export const fetchPostsList = createAsyncThunk('posts/fetchPostList', async (_, { getState }) => {
  const logger = getLogger('fetch-post-list')
  const state = getState() as any
  const publication = selectPublication(state)
  const sort = selectPostsQuerySort(state)
  const filter = selectPostsFilter(state)
  const cursor = selectPostsCursor(state)

  const where = { ...(filter || {}), publication: publication.id }

  logger.info('Query for cursor:', cursor ? safeParse(atob(cursor as string)) : 'empty')

  const { data, meta } = await listPostsQuery({
    sort: [sort],
    limit: PAGE_SIZE,
    after: cursor || '',
    where,
  })

  const { totalCount, hasMoreItems } = meta

  let startIndex = totalCount - data.length
  if (hasMoreItems) {
    const cursorInfo = safeParse(atob(meta.cursor as string))
    startIndex = cursorInfo.lastIndex - data.length + 1
    logger.info('Cursor info', cursorInfo)
  }

  logger.info(
    'Posts',
    JSON.stringify(
      data.map(({ id, title }) => id + '-' + title),
      null,
      4,
    ),
  )

  return {
    posts: data,
    totalCount,
    hasMoreRecords: hasMoreItems,
    startIndex,
    cursor: cursor || '',
    nextCursor: meta.cursor,
  }
})

import { gql } from '@tellimer/graphql-client'
import { ERROR_FIELDS } from '../common'

export const deleteAccountMutation = gql`
  mutation DeleteAccount {
    deleteAccount {
      data
      ${ERROR_FIELDS}
    }
  }
`

import { gql } from '@tellimer/graphql-client'
import { query } from 'gql'
import { handleQueryResponse } from 'gql/util'
import { Publication } from 'types/publication.types'
import { ERROR_FIELDS, PUBLICATION_COMMON_FIELDS } from '../fragments'

export const QUERY_GET_PUBLICATION = gql`
  ${PUBLICATION_COMMON_FIELDS}
  query GetPublication($id: ID!) {
    publication: getPublication(id: $id ) {
      data {
        ...PublicationCommonFields
      }
      ${ERROR_FIELDS}
    }
  }
`

export const getPublicationQuery = async (id?: string) => {
  if (!id) {
    throw new Error("Can't get publication details without an id")
  }

  const res = await query(QUERY_GET_PUBLICATION, { id })
  return handleQueryResponse(res, 'publication') as Publication
}

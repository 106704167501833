import type { AppState } from '../store'

export const selectIsOnboardingProgressLoading = (state: AppState) =>
  state.onboardingProgress.isLoading
export const selectOnboardingProgress = (state: AppState) => state.onboardingProgress?.progress
export const selectIsOnboardingDismissed = (state: AppState) =>
  Boolean(state.onboardingProgress?.progress?.isDismissed)

export const selectIsOnboardingCompleted = (state: AppState) =>
  Boolean(state.auth?.creator?.hasCompletedOnboarding)

export const selectOnboardingCurrentStep = (state: AppState) =>
  state.onboardingProgress?.currentStep
export const selectIsSendingVerifyEmail = (state: AppState) =>
  state.onboardingProgress?.isSendingVerifyEmail
